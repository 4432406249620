import { SupportedWallet, useWallets } from "../lib";

import { Button } from "../../../components/buttons";

export const ConnectWallets = () => {
    const { connectedWallet, disconnectWallet, connectWallet, enabledWallets } = useWallets();

    const onClickConnectWallet = (type: SupportedWallet) => () => {
        connectWallet(type);
    };

    return connectedWallet ? (
        <Button type="button" onClick={disconnectWallet}>
            Disconnect Wallet
        </Button>
    ) : (
        <>
            {enabledWallets.stoic && (
                <Button type="button" onClick={onClickConnectWallet("stoic")}>
                    Connect Stoic
                </Button>
            )}
            {enabledWallets.plug && (
                <Button type="button" onClick={onClickConnectWallet("plug")}>
                    Connect Plug
                </Button>
            )}
        </>
    );
};
