import tw from "tailwind-styled-components";


export const Seperator = tw.div`
    border-t
    mb-2
`;

export const Info = tw.div`
    flex
    flex-grow
    justify-center
    items-center
    text-center
`;

export const SearchField = tw.input`
    w-full
    my-2
    py-1.5
    px-3
    text-sm
    rounded-full
    bg-gray-100
    placeholder:text-neutral-500
    focus:outline-none
`;

export const ScrollContainer = tw.div`
    flex-grow
    overflow-auto
    -mr-3
    pr-3
`;

export const PlotListItem = tw.div`
    flex
    items-center
    space-x-6
    p-2
    rounded-lg
    bg-opacity-20
    cursor-pointer
    hover:shadow-md
    hover:bg-yellow-50
`;

export const PlotSaleBanner = tw.div`
    text-2xs
    text-white
    font-bold
    bg-for-sale
    border-2
    border-white
    text-center
    -rotate-45
    mt-2
    -ml-7
    shadow-md
`;

export const PlotEditButton = tw.button`
    flex
    flex-none
    justify-center
    items-center
    rounded-xl
    border
    w-8
    h-8
    border-neutral-500
    text-neutral-500
    bg-white
`;

export const PlotData = tw.div`
    flex
    flex-col
    flex-grow
    space-y-0.5
`;

export const PlotDataTitle = tw.div`
    text-sm
    font-semibold
`;

export const PlotDataMint = tw.div`
    text-xs
    text-gray-700
`;

export const FilterPill = tw.div`
    font-medium
    text-xs
    text-white
    bg-primary
    px-3
    py-1
    rounded-full
`;

export const ClearFilter = tw.div`
    font-medium
    text-xs
    text-red-800
    px-3
    py-1
`;
