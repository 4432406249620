import {
    FC,
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import TimeAgo from "javascript-time-ago";
import TimeAgoEng from "javascript-time-ago/locale/en";

import { emptyProfile } from "../../../assets";
import { DCityActivity, useActivityQuery } from "../../../store/api";

import { ResultPager } from "./result-pager";

import { Info, ScrollContainer } from "./drawer-activity.styles";
import { ActivityEventType } from "../../../icp/dcity_api/dcity-api.did";

const PAGE_SIZE = 60;

TimeAgo.addDefaultLocale(TimeAgoEng);
const timeAgo = new TimeAgo("en-US");

const ActivityItem: FC<
    PropsWithChildren<{ item: DCityActivity; className?: string }>
> = ({ children, item }) => {
    return (
        <div className="flex">
            {item.profileImage ? (
                <img
                    src={item.profileImage}
                    alt={`${item.profileName}'s avatar`}
                    className="block w-14 h-14 rounded-full"
                />
            ) : (
                <img
                    src={emptyProfile}
                    alt={`${item.profileName}'s missing avatar`}
                    className="block w-14 h-14 rounded-full"
                />
            )}
            <div className="flex flex-col pl-4 justify-center">
                <div className="text-sm">{children}</div>
                <div className="text-xs text-gray-900">
                    {timeAgo.format(item.timestamp * 1000)}
                </div>
            </div>
        </div>
    );
};

export const DrawerActivity: FC<{ onView: (mint: number) => void }> = ({
    onView,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [pageIdx, setPageIdx] = useState(0);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0);
        }
    }, [pageIdx]);

    const { data, isFetching } = useActivityQuery(0);
    const pagedData = useMemo(() => {
        const startIdx = pageIdx * PAGE_SIZE;
        const endIdx = startIdx + PAGE_SIZE;

        return (data || []).slice(startIdx, endIdx);
    }, [data, pageIdx]);

    return (
        <>
            {isFetching ? (
                <Info>Loading Activity</Info>
            ) : (
                <ScrollContainer ref={scrollRef}>
                    {pagedData.map((item) => (
                        <div key={item.id} className="pt-2">
                            {item.type ===
                                ActivityEventType.PlotMetaUpdated && (
                                <ActivityItem item={item}>
                                    <strong>{item.profileName}</strong> updated{" "}
                                    <strong
                                        role="button"
                                        onClick={() => onView(item.mint || 0)}
                                    >
                                        Plot #{(item.mint || 0) + 1}
                                    </strong>
                                </ActivityItem>
                            )}
                            {item.type === ActivityEventType.ProfileCreated && (
                                <ActivityItem item={item}>
                                    <strong>{item.profileName}</strong> joined
                                    the city
                                </ActivityItem>
                            )}
                        </div>
                    ))}
                </ScrollContainer>
            )}
            <ResultPager
                pageIdx={pageIdx}
                pageSize={PAGE_SIZE}
                totalRecords={data ? data.length : 0}
                onChangePageIdx={setPageIdx}
            />
        </>
    );
};
