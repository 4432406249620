import { FC, useCallback, useState } from "react";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { useClassToggle } from "../lib/use-class-toggle";

import {
    CloseButton,
    Container,
    FilterContainer,
    FilterPill,
    FilterTitle,
    Title,
} from "./filter-drawer.styles";

import json from "../../../icp/nft/dcity-attributes.json";

const FILTERS = json.attr.map((attrValues, idx) => {
    return {
        id: idx,
        name: attrValues[0],
        values: attrValues.slice(1),
    };
});

const FILTER_NAMES: Record<string, string> = {
    Background: "Background",
    Behind: "Behind",
    Color: "Color",
    Entrance: "Entrance",
    F1_facade: "Floor 1 Facade",
    F2_facade: "Floor 2 Facade",
    F2_front: "Floor 2 Front",
    F3_facade: "Floor 3 Facade",
    F3_front: "Floor 3 Front",
    Foreground: "Foreground",
    Ground: "Ground",
    Roof: "Roof",
};

export const FilterDrawer: FC<{
    filters: Map<number, number[]>;
    visible: boolean;
    onClose: () => void;
    onChangeSearch: (filters: Map<number, number[]>) => void;
}> = ({ filters, visible, onClose, onChangeSearch }) => {
    const containerRef = useClassToggle(
        visible,
        useCallback((active: boolean, { classList }: HTMLElement) => {
            if (active) {
                classList.remove("opacity-0");
                classList.add("translate-x-full");
                classList.add("opacity-1");
            } else {
                classList.remove("opacity-1");
                classList.remove("translate-x-full");
                classList.add("opacity-0");
            }
        }, [])
    );
    const [expanded, setExpanded] = useState<Record<string, boolean>>({});

    const onChangeFilter = (filterName: string, filterValue: string) => () => {
        const nextFilters = new Map<number, number[]>(filters);
        const filterIdx = FILTERS.findIndex(
            (filter) => filter.name === filterName
        );
        const filterDef = FILTERS[filterIdx];

        if (filterDef) {
            const valueIdx = filterDef.values.indexOf(filterValue);
            const existingFilter = nextFilters.get(filterIdx);

            if (existingFilter) {
                const existingValueIdx = existingFilter.indexOf(valueIdx);

                if (existingValueIdx === -1) {
                    existingFilter.push(valueIdx);
                } else if (existingFilter.length > 1) {
                    existingFilter.splice(existingValueIdx, 1);
                } else {
                    nextFilters.delete(filterIdx);
                }
            } else {
                nextFilters.set(filterIdx, [valueIdx]);
            }
        }

        onChangeSearch(nextFilters);
    };

    return (
        <Container ref={containerRef}>
            <Title>Filter Attributes</Title>
            <CloseButton type="button" onClick={onClose}>
                <XMarkIcon width={16} height={16} />
            </CloseButton>
            <div className="space-y-2 mt-3 -mr-3 pr-3 overflow-auto">
                {FILTERS.map(({ name, values }, filterIdx) => {
                    const activeFilters = filters.get(filterIdx);
                    const activeFilterCount = activeFilters
                        ? activeFilters.length
                        : 0;

                    return (
                        <FilterContainer key={name}>
                            <div
                                className="flex justify-between select-none pr-6 cursor-pointer"
                                onClick={() =>
                                    setExpanded({
                                        ...expanded,
                                        [name]: !expanded[name],
                                    })
                                }
                            >
                                <FilterTitle>{FILTER_NAMES[name]}</FilterTitle>
                                {activeFilterCount > 0 && (
                                    <FilterPill>
                                        {activeFilterCount} Applied
                                    </FilterPill>
                                )}
                                <ChevronDownIcon
                                    width={18}
                                    height={18}
                                    className={
                                        "absolute right-2 top-3.5 transition-transform duration-300" +
                                        (expanded[name] ? " rotate-180" : "")
                                    }
                                />
                            </div>
                            <div
                                className="overflow-hidden transition-all px-2 -mx-2"
                                style={{
                                    height: expanded[name]
                                        ? values.length * 28
                                        : 0,
                                }}
                            >
                                {values.map((val, valIdx) => (
                                    <label
                                        key={val}
                                        className="cursor-pointer select-none flex space-x-2 items-center py-1"
                                    >
                                        <input
                                            type="checkbox"
                                            className="appearance-none border border-gray-400 w-4 h-4 rounded flex items-center justify-center before:transition-opacity before:bg-primary before:w-2.5 before:h-2.5 before:rounded-sm before:opacity-0 checked:before:opacity-100"
                                            onChange={onChangeFilter(name, val)}
                                            checked={
                                                activeFilters
                                                    ? activeFilters.indexOf(
                                                          valIdx
                                                      ) !== -1
                                                    : false
                                            }
                                        />
                                        <div className="text-sm text-gray-700">
                                            {val}
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </FilterContainer>
                    );
                })}
            </div>
        </Container>
    );
};
