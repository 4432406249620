import tw from "tailwind-styled-components";

export const Container = tw.div`
    flex
    flex-col
    absolute
    left-0
    top-6
    bottom-6
    w-96
    p-3
    rounded-lg
    bg-gray-100
    transition-all
    duration-300
    opacity-0
`;

export const Title = tw.h4`
    text-sm
    font-medium
    text-gray-800
`;

export const Seperator = tw.div`
    border-t
    mb-2
`;

export const CloseButton = tw.button`
    absolute
    right-2.5
    top-2.5
    text-gray-800
    p-1
`;

export const FilterContainer = tw.div`
    relative
    bg-white
    p-3
    rounded-md
`;

export const FilterTitle = tw.div`
    flex
    justify-center
    items-center
    font-medium
    text-sm
    h-6
`;

export const FilterPill = tw.div`
    font-medium
    text-xs
    text-white
    bg-primary
    px-3
    py-1
    rounded-full
`;
