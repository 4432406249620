import { Bars3Icon } from "@heroicons/react/24/outline";
import { FC } from "react";

import { logo } from "../../../assets";
import { Button, UserButton, WalletButton } from "../../../components/buttons";
import { ConnectWallets } from "../../wallets/components/connect";
import { useWallets } from "../../wallets/lib";

import { Container, Menu } from "./header.styles";

export const GameHeader: FC<{
    onClickHamburger: () => void;
    onClickEditProfile: () => void;
    onClickViewReports: () => void;
    onClickLogo: () => void;
}> = ({
    onClickHamburger,
    onClickEditProfile,
    onClickViewReports,
    onClickLogo,
}) => {
    const { connectedWallet, isAdmin } = useWallets();

    const onClickFeedback = () => {
        window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSecy_BhTH_vRvnam5NlHO9ZpoVQXfOe7-w-WQGiq-MRqFFLXA/viewform?usp=sf_link",
            "_blank"
        );
    };

    return (
        <Container>
            <div className="absolute left-6 group flex items-center">
                <button onClick={onClickHamburger}>
                    <Bars3Icon width={32} height={32} />
                </button>
            </div>
            <div
                role="button"
                style={{
                    width: 192,
                    height: 50,
                    backgroundImage: `url(${logo})`,
                    backgroundSize: "192px 50px",
                }}
                onClick={onClickLogo}
            />
            <div className="absolute right-6 group">
                {connectedWallet ? (
                    <UserButton className="block" />
                ) : (
                    <WalletButton className="block" />
                )}
                <Menu>
                    {isAdmin && (
                        <Button type="button" onClick={onClickViewReports}>
                            View Reports
                        </Button>
                    )}
                    {!!connectedWallet && (
                        <Button type="button" onClick={onClickEditProfile}>
                            Edit Profile
                        </Button>
                    )}
                    <Button type="button" onClick={onClickFeedback}>
                        Give Feedback
                    </Button>
                    <ConnectWallets />
                </Menu>
            </div>
        </Container>
    );
};
