import tw from "tailwind-styled-components";

export const Info = tw.div`
    flex
    flex-grow
    justify-center
    items-center
    text-center
`;

export const ScrollContainer = tw.div`
    flex-grow
    overflow-auto
    -mr-3
    pr-3
`;
