import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { FC, useMemo } from "react";

export const ResultPager: FC<{
    pageIdx: number;
    pageSize: number;
    totalRecords: number;
    onChangePageIdx: (value: number) => void;
}> = ({ pageIdx, pageSize, totalRecords, onChangePageIdx }) => {
    const totalPages = useMemo(() => {
        return Math.ceil(totalRecords / pageSize);
    }, [pageSize, totalRecords]);

    const leftPages = useMemo(() => {
        const result: number[] = [];

        for (
            let idx = Math.max(Math.min(pageIdx, totalPages - 6), 0), c = 0;
            c < 3 && idx < totalPages - 3;
            idx++, c++
        ) {
            result.push(idx);
        }

        return result;
    }, [pageIdx, totalPages]);

    const rightPages = useMemo(() => {
        const result: number[] = [];

        for (let idx = Math.max(totalPages - 3, 0); idx < totalPages; idx++) {
            result.push(idx);
        }

        return result;
    }, [totalPages]);

    const pageButtonStyle = (idx: number) => {
        return `flex text-xs w-5 rounded-full justify-center items-center ${
            idx === pageIdx ? "font-medium text-white bg-anchor" : ""
        }`;
    };

    return (
        <div className="flex justify-between select-none text-sm bg-white mt-3 -mb-3 py-3 border-t">
            <div
                className={pageIdx > 0 ? "" : "invisible"}
                role="button"
                onClick={() => onChangePageIdx(Math.max(pageIdx - 1, 0))}
            >
                <ChevronLeftIcon width={18} height={18} />
            </div>
            {leftPages.map((idx) => {
                return (
                    <div
                        key={idx}
                        onClick={() => onChangePageIdx(idx)}
                        role="button"
                        className={pageButtonStyle(idx)}
                    >
                        {idx + 1}
                    </div>
                );
            })}
            {pageIdx < totalPages - 6 && <div>…</div>}
            {rightPages.map((idx) => {
                return (
                    <div
                        key={idx}
                        onClick={() => onChangePageIdx(idx)}
                        role="button"
                        className={pageButtonStyle(idx)}
                    >
                        {idx + 1}
                    </div>
                );
            })}
            <div
                className={pageIdx < totalPages - 1 ? "" : "invisible"}
                role="button"
                onClick={() =>
                    onChangePageIdx(Math.min(pageIdx + 1, totalPages - 1))
                }
            >
                <ChevronRightIcon width={18} height={18} />
            </div>
        </div>
    );
};
