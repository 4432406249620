import { FC, useState } from "react";

import {
    welcome00CityBackground,
    welcome01MetaDemo,
} from "../../../assets";
import { Button } from "../../../components/buttons";

export type Announcement = {
    id: string;
    title: string;
    description: string;
    img?: {
        url: string;
        alt: string;
        width: number;
        height: number;
    };
};

export const ANNOUNCEMENTS: Announcement[] = [
    {
        id: "discover",
        title: "Explore, Discover, Search",
        description:
            "Explore the DCity and see all there is to discover. Search for specific plots by name, plot number, or filter by specific attributes.",
    },
    {
        id: "customize",
        title: "Customize & Share",
        description:
            "Customize the name, bio, and add a url to your plots. Share them with others through social media.",
        img: {
            url: welcome01MetaDemo,
            alt: "Customize & Share experience",
            width: 440,
            height: 159,
        },
    },
    {
        id: "social",
        title: "Socialize & Engage",
        description:
            "Chat with other DCity holders and get involved in community events, City Council conversations, and ballot box votes.",
    },
];

export const OnboardingAnnouncements: FC<{
    announcements: Announcement[];
    onDone: () => void;
}> = ({ announcements, onDone }) => {
    const [announcementIdx, setAnnouncementIdx] = useState(0);
    const announcement = announcements[announcementIdx];

    const prevAnnouncement = () => {
        setAnnouncementIdx(Math.max(announcementIdx - 1, 0));
    };

    const nextAnnouncement = () => {
        if (announcementIdx + 1 >= announcements.length) {
            onDone();
        } else {
            setAnnouncementIdx(announcementIdx + 1);
        }
    };

    if (!announcement) {
        return null;
    }

    return (
        <div className="absolute inset-0 flex justify-center items-center z-20">
            <div
                className="flex flex-col p-6 bg-white rounded-2xl"
                style={{
                    width: 600,
                    height: 348,
                }}
            >
                <h1 className="font-bold text-lg text-center text-primary mb-2">
                    {announcement.title}
                </h1>
                <p
                    className="text-xs mx-auto text-center text-neutral-800 mb-4"
                    style={{ width: 440 }}
                >
                    {announcement.description}
                </p>
                <div
                    className="flex justify-center items-center mb-3"
                    style={{
                        width: 552,
                        height: 165,
                        backgroundImage: `url(${welcome00CityBackground})`,
                        backgroundSize: "552px 165px",
                    }}
                >
                    {!!announcement.img && (
                        <img
                            src={announcement.img.url}
                            style={{
                                width: announcement.img.width,
                                height: announcement.img.height,
                            }}
                            alt={announcement.img.alt}
                        />
                    )}
                </div>
                <div className="flex justify-between">
                    <Button
                        $primary
                        $outline
                        $small
                        $rounded
                        className={announcementIdx === 0 ? "invisible" : ""}
                        onClick={prevAnnouncement}
                    >
                        Back
                    </Button>
                    <div>
                        <div className="flex space-x-2 justify-center mb-1">
                            {announcements.map(({ id }, idx) => (
                                <div
                                    key={id}
                                    className={
                                        "w-2 h-2 rounded-full " +
                                        (idx === announcementIdx
                                            ? "bg-primary"
                                            : "bg-gray-400")
                                    }
                                />
                            ))}
                        </div>
                        <Button
                            $xs
                            $rounded
                            onClick={onDone}
                        >
                            Skip Tutorial
                        </Button>
                    </div>
                    <Button $primary $small $rounded onClick={nextAnnouncement}>
                        {announcementIdx === announcements.length - 1
                            ? "Close"
                            : "Next"}
                    </Button>
                </div>
            </div>
        </div>
    );
};
