import { FC } from "react";

export const MultiTextField: FC<{
    label: string;
    className?: string;
    value: string | number;
    maxLength: number;
    onChange: (newValue: string) => void;
}> = ({ label, value, className, maxLength, onChange }) => {
    return (
        <label className="block mb-4">
            <div className="text-sm font-medium mb-0.5 mx-3">{label}</div>
            <textarea
                value={value}
                maxLength={maxLength}
                className={
                    "bg-gray-100 rounded-2xl text-sm p-2 px-3 resize-none " +
                    (className || "")
                }
                onChange={(e) => onChange(e.target.value)}
            />
        </label>
    );
};
