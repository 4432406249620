import { FC, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Principal } from "@dfinity/principal";

import { useReportsQuery } from "../../../store/api";
import { dcityTokenIdentifier } from "../../../icp/nft";
import { createDCityActor } from "../../../icp/dcity_api";
import { PlotReport } from "../../../icp/dcity_api/dcity-api.did";

import { Button } from "../../../components/buttons";

import {
    CloseButton,
    ModalBackdrop,
    ModalContent,
} from "../../nfts/components/view-reports.styles";

function zPad(value: number) {
    return ("00" + value).slice(-2);
}

function formatDate(seconds: number) {
    const dt = new Date(seconds * 1000);

    return `${zPad(dt.getMonth() + 1)}/${zPad(
        dt.getDate()
    )}/${dt.getFullYear()} ${zPad(dt.getHours())}:${zPad(dt.getMinutes())}`;
}

export const ViewReports: FC<{
    onViewReport: (mint: number) => void;
    onClose: () => void;
}> = ({ onViewReport, onClose }) => {
    const { data, refetch } = useReportsQuery();
    const [deleting, setDeleting] = useState(false);

    const onClickDelete = (report: PlotReport) => async () => {
        try {
            setDeleting(true);

            const dcity = createDCityActor(true);

            await dcity.remove_report(
                report.mint,
                Principal.fromText(report.reported_by)
            );

            refetch();
        } finally {
            setDeleting(false);
        }
    };

    const onClickView = (mint: number) => () => {
        onViewReport(mint);
    };

    return (
        <ModalBackdrop>
            {data && (
                <ModalContent>
                    <div className="flex flex-col">
                        <div className="text-xl mb-4">
                            {data.total ? data.total : "No"} Reports
                        </div>
                        {data.reports.map((report, idx) => (
                            <div
                                key={`${report.mint}:${report.reported_by}`}
                                className={idx === 0 ? "" : "mt-6"}
                            >
                                <div className="flex items-center justify-between mb-2 pb-2 border-solid border-b border-gray-100">
                                    <strong className="font-bold text-lg">
                                        #{report.mint + 1} -{" "}
                                        {formatDate(report.time_reported)}
                                    </strong>
                                    {!deleting && (
                                        <div className="space-x-4">
                                            <Button
                                                $small
                                                onClick={onClickView(
                                                    report.mint
                                                )}
                                            >
                                                View Plot
                                            </Button>
                                            <Button
                                                $small
                                                onClick={onClickDelete(report)}
                                            >
                                                Delete Report
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div>{report.reason}</div>
                            </div>
                        ))}
                    </div>
                    <CloseButton type="button" onClick={onClose}>
                        <XMarkIcon width={20} height={20} />
                    </CloseButton>
                </ModalContent>
            )}
        </ModalBackdrop>
    );
};
