import { FC, useMemo, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { currency } from "../../../util/formatters";
import { useNftDetailsQuery } from "../../../store/api";
import { dcityTokenIdentifier } from "../../../icp/nft";
import { accountIdBytes, createDCityActor } from "../../../icp/dcity_api";
import { useWallets } from "../../wallets/lib";

import { emptyProfile, icp } from "../../../assets";
import { Button } from "../../../components/buttons";
import { DCityImage } from "./dcity-image";
import { LoadingAnimation } from "../../../components/loading-animation";
import { MultiTextField } from "../../../components/multi-text-field";

import {
    CloseButton,
    ModalBackdrop,
    ModalContent,
    ModalLoadingContainer,
} from "../../nfts/components/view.styles";

export const ViewPlot: FC<{
    mint: number;
    onEdit: () => void;
    onClose: () => void;
}> = ({ mint, onEdit, onClose }) => {
    const { isAdmin, connectedWallet } = useWallets();
    const { data: nftDetails, refetch } = useNftDetailsQuery(mint);
    const tokenId = useMemo(() => dcityTokenIdentifier(mint).toText(), [mint]);
    const [isBlocking, setIsBlocking] = useState(false);
    const [isReporting, setIsReporting] = useState(false);
    const [isSubmittingReport, setIsSubmittingReport] = useState(false);
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const [report, setReport] = useState("");

    const onClickBlockProfile = async () => {
        const dcity = createDCityActor(true);

        setIsBlocking(true);

        if (nftDetails?.ownerId) {
            await dcity.admin_adjust(accountIdBytes(nftDetails.ownerId));
        }

        await refetch();

        setIsBlocking(false);
    };

    const onClickStartReport = () => {
        setIsReporting(true);
    };

    const onClickCancelReport = () => {
        setIsReporting(false);
    };

    const onClickSubmitReport = async () => {
        const dcity = createDCityActor(true);

        setIsSubmittingReport(true);

        if (nftDetails) {
            await dcity.report_meta(nftDetails.mint, report);
        }

        setReportSubmitted(true);
        setIsSubmittingReport(false);
        setIsReporting(false);
    };

    const onClickEdit = () => {
        onEdit();
    };

    return (
        <ModalBackdrop>
            {nftDetails ? (
                <ModalContent>
                    <div className="flex flex-col space-y-6 lg:flex-row lg:space-x-6 lg:space-y-0">
                        <div className="flex flex-col items-center">
                            <DCityImage mint={mint} className="w-44 mb-2" />
                            <div className="flex justify-between items-center w-44">
                                <span>Plot #{mint + 1}</span>
                                {!!nftDetails.price && (
                                    <a
                                        href={`https://entrepot.app/marketplace/asset/${tokenId}`}
                                        className="inline-flex space-x-1 items-center text-sm text-gray-600"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={icp}
                                            alt="ICP"
                                            className="w-4 h-4"
                                        />
                                        <span>
                                            {currency(nftDetails.price)}
                                        </span>
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col flex-grow lg:pr-6">
                            <div className="flex h-12">
                                {!!nftDetails.nickname && (
                                    <div className="flex-1">
                                        <div className="text-lg leading-5 font-medium">
                                            {nftDetails.nickname}
                                        </div>
                                    </div>
                                )}
                                {!!nftDetails.owner && (
                                    <div className="flex flex-1 space-x-2 items-center">
                                        {nftDetails.owner.image ? (
                                            <img
                                                src={nftDetails.owner.image}
                                                alt={`${nftDetails.owner.nickname}'s avatar`}
                                                className="block w-10 h-10 rounded-full"
                                            />
                                        ) : (
                                            <img
                                                src={emptyProfile}
                                                alt={`${nftDetails.owner.nickname}'s missing avatar`}
                                                className="block w-14 h-14 rounded-full"
                                            />
                                        )}
                                        <div className="flex flex-col">
                                            {!!nftDetails.owner.nickname && (
                                                <div className="flex-1">
                                                    <div className="text-base text-gray-900">
                                                        {
                                                            nftDetails.owner
                                                                .nickname
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex-1">
                                                <div className="text-sm text-gray-700">
                                                    {nftDetails.owner
                                                        .twitterHandle ? (
                                                        <a
                                                            href={
                                                                "https://twitter.com/" +
                                                                nftDetails.owner
                                                                    .twitterHandle
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            @
                                                            {
                                                                nftDetails.owner
                                                                    .twitterHandle
                                                            }
                                                        </a>
                                                    ) : (
                                                        "\u00A0"
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {nftDetails.$empty && (
                                <div className="flex flex-grow justify-center items-center text-xl whitespace-pre-wrap text-center">
                                    The Nft Owner has{"\n"}not added any info
                                </div>
                            )}
                            {!!nftDetails.about && (
                                <div>
                                    <div className="text-base text-gray-700">
                                        {nftDetails.about}
                                    </div>
                                </div>
                            )}
                            {isAdmin && (
                                <div className="flex justify-end space-x-3 mt-4">
                                    {nftDetails.ownerBlocked ? (
                                        <Button
                                            $medium
                                            $primary
                                            $danger
                                            disabled={isBlocking}
                                            onClick={onClickBlockProfile}
                                        >
                                            Un-Block Profile
                                        </Button>
                                    ) : (
                                        <Button
                                            $medium
                                            $primary
                                            $danger
                                            disabled={isBlocking}
                                            onClick={onClickBlockProfile}
                                        >
                                            Block Profile
                                        </Button>
                                    )}
                                    <Button
                                        $medium
                                        $primary
                                        onClick={onClickEdit}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            )}
                            {!isAdmin &&
                                !!connectedWallet &&
                                !isReporting &&
                                !reportSubmitted && (
                                    <div className="flex flex-1 items-end justify-end space-x-3">
                                        <Button
                                            $xs
                                            $dark
                                            $outline
                                            disabled={isReporting}
                                            onClick={onClickStartReport}
                                        >
                                            Report Content
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {isReporting && (
                        <div className="flex flex-col border-t mt-6 pt-6">
                            <MultiTextField
                                className="w-full h-20"
                                label="Describe Your Report"
                                value={report}
                                onChange={setReport}
                                maxLength={250}
                            />
                            <div className="flex justify-end space-x-4">
                                <Button
                                    $medium
                                    $dark
                                    $outline
                                    disabled={isSubmittingReport}
                                    onClick={onClickCancelReport}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    $medium
                                    $primary
                                    disabled={isSubmittingReport}
                                    onClick={onClickSubmitReport}
                                >
                                    Submit Report
                                </Button>
                            </div>
                        </div>
                    )}
                    {reportSubmitted && (
                        <div className="flex flex-col border-t mt-6 pt-6">
                            Your report has been submitted.
                        </div>
                    )}
                    <CloseButton type="button" onClick={onClose}>
                        <XMarkIcon width={20} height={20} />
                    </CloseButton>
                </ModalContent>
            ) : (
                <ModalContent>
                    <ModalLoadingContainer>
                        <LoadingAnimation />
                    </ModalLoadingContainer>
                </ModalContent>
            )}
        </ModalBackdrop>
    );
};
