import emptyProfileUrl from "./empty-profile.svg";
import icpUrl from "./icp.png";
import logoUrl from "./logo.svg";
import walletUrl from "./wallet.svg";
import welcome00CityBackgroundUrl from "./welcome_00_city_background.png";
import welcome00SplashDcityUrl from "./welcome_00_splash_dcity.svg";
import welcome00SplashUrl from "./welcome_00_splash.png";
import welcome01MetaDemoUrl from "./welcome_01_meta_demo.png";

export const emptyProfile = emptyProfileUrl;
export const icp = icpUrl;
export const logo = logoUrl;
export const wallet = walletUrl;
export const welcome00CityBackground = welcome00CityBackgroundUrl;
export const welcome00SplashDcity = welcome00SplashDcityUrl;
export const welcome00Splash = welcome00SplashUrl;
export const welcome01MetaDemo = welcome01MetaDemoUrl;
