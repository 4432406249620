import { FC } from "react";
import { EditProfileForm } from "./edit-profile-form";
import { ModalBackdrop, ModalContent } from "./edit-profile.styles";

export const EditProfile: FC<{ onDone: () => void }> = ({ onDone }) => {
    return (
        <ModalBackdrop>
            <ModalContent>
                <h3 className="font-bold text-xl text-center mb-3">
                    Edit Your DCity Profile
                </h3>
                <EditProfileForm
                    saveBtnText="Save"
                    onSave={onDone}
                    onCancel={onDone}
                />
            </ModalContent>
        </ModalBackdrop>
    );
};
