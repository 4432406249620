export const SpotifyPlaylist = () => {
    return (
        <iframe
            src="https://open.spotify.com/embed/playlist/2nGvWnem8Xr0tINOcDpdrW?utm_source=generator"
            className="h-20 w-full max-w-xs rounded-lg absolute right-6 bottom-6"
            width="100%"
            height="80"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            allowFullScreen
            title="Deep Focus"
        ></iframe>
    );
};
