import { HttpAgent } from "@dfinity/agent";
import { StoicIdentity, StoicSignIdentity } from "ic-stoic-identity";
import { WalletAccount } from "../../../store/api";

var cachedIdentity: Promise<false | StoicSignIdentity> | null = null;

const getIdentity = () => {
    if (!cachedIdentity) {
        cachedIdentity = StoicIdentity.load();
    }

    return cachedIdentity;
};

export const isStoicEnabled = () => {
    return true;
};

export const stoicSigningIdentity = async () => {
    return await getIdentity();
};

export const getStoicAccounts = async (): Promise<WalletAccount[]> => {
    var identity = await getIdentity();

    if (identity) {
        const accountsJson = await identity.accounts();

        return JSON.parse(accountsJson) as WalletAccount[];
    }

    return [];
};

export const connectStoic = async (): Promise<
    [WalletAccount[] | null, HttpAgent | null]
> => {
    try {
        const connectPomise = StoicIdentity.connect();
        cachedIdentity = connectPomise;

        const identity = await connectPomise;
        const accountsJson = await identity.accounts();
        const accounts = JSON.parse(accountsJson) as WalletAccount[];
        const agent = new HttpAgent({
            host: process.env.DCITY_API_HOST,
            identity
        });

        return [accounts.filter((x) => x.address), agent];
    } catch {
        return [null, null];
    }
};

export const disconnectStoic = () => {
    cachedIdentity = null;
    StoicIdentity.disconnect();
};
