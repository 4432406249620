import tw from "tailwind-styled-components";

export const ModalBackdrop = tw.div`
    bg-opacity-30
    bg-black
    fixed
    inset-0
    flex
    justify-center
    items-center
    p-4
    pt-24
    overflow-auto
`;

export const ModalContent = tw.div`
    relative
    flex
    flex-col
    bg-white
    rounded-2xl
    p-6
    w-full
    max-w-2xl
    my-auto
`;

export const ModalActions = tw.div`
    flex
    justify-end
    space-x-2
    pt-4
`;

export const CloseButton = tw.button`
    absolute
    right-6
    top-6
`;

export const ErrorAlert = tw.div`
    inline-flex
    border
    border-red-900
    bg-red-100
    text-red-900
    rounded-md
    p-2
    mb-4
`;
