import { MutableRefObject, useEffect, useRef } from "react";

export const useClassToggle = (
    classEnabled: boolean,
    token: string | ((active: boolean, element: HTMLElement) => void)
): MutableRefObject<HTMLElement | null> => {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (ref.current) {
            const { classList } = ref.current;

            if (typeof token === "string") {
                if (classEnabled) {
                    classList.add(token);
                } else {
                    classList.remove(token);
                }
            } else {
                token(classEnabled, ref.current);
            }
        }
    }, [classEnabled, token]);

    return ref;
};
