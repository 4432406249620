import type { InterfaceFactory } from "@dfinity/candid/lib/cjs/idl";
import type { Principal } from "@dfinity/principal";

export type PlotReport = {
    mint: number;
    time_reported: number;
    reported_by: string;
    reason: string;
};

export type DCityPlot = {
    about: string;
    mint: number;
    nickname: string;
};

export type DCityProfile = {
    nickname: string;
    avatar_nft: [Principal] | [];
    avatar_mint: [number] | [];
    twitter_handle: string;
};

export type DCityMetaResults = {
    next_start_at_id: [Principal] | [];
    plots: DCityPlot[];
};

export type Result = {
    Ok: boolean;
    Err: string;
};

export type ActivityEventWithUser = {
    mint: [number] | [],
    timestamp: number,
    icp_amount: [number] | [],
    event_type: number,
    profile: [DCityProfile] | [],
}

export enum ActivityEventType {
    PlotMetaUpdated = 1,
    ProfileCreated,
}

export interface DCityApiService {
    admin_adjust: (account_id: number[]) => Promise<Result>;
    can_edit_all: () => Promise<boolean>;
    read: (
        mint: number,
        owner_account_id: [number[]]
    ) => Promise<
        [[DCityPlot | undefined], [DCityProfile | undefined], boolean]
    >;
    read_activities: (eventsSince: number) => Promise<ActivityEventWithUser[]>,
    read_meta: (start_at_id: Principal) => Promise<DCityMetaResults>;
    read_profile: () => Promise<[DCityProfile] | []>;
    read_reports: () => Promise<{
        Ok: { reports: PlotReport[]; total: number };
        Err: string;
    }>;
    remove_report: (mint: number, reporter: Principal) => Promise<Result>;
    report_meta: (mint: number, reason: string) => Promise<Result>;
    update_plot: (plot: DCityPlot) => Promise<Result>;
    update_profile: (profile: DCityProfile) => Promise<Result>;
}

export const idlFactory: InterfaceFactory = ({ IDL }) => {
    const Result = IDL.Variant({ Ok: IDL.Bool, Err: IDL.Text });
    const DCityPlot = IDL.Record({
        about: IDL.Text,
        nickname: IDL.Text,
        mint: IDL.Nat32,
    });
    const DCityProfile = IDL.Record({
        nickname: IDL.Text,
        avatar_nft: IDL.Opt(IDL.Principal),
        avatar_mint: IDL.Opt(IDL.Nat32),
        twitter_handle: IDL.Text,
    });
    const ActivityEventWithUser = IDL.Record({
        mint: IDL.Opt(IDL.Nat32),
        timestamp: IDL.Nat32,
        icp_amount: IDL.Opt(IDL.Nat32),
        event_type: IDL.Nat8,
        profile: IDL.Opt(DCityProfile),
    });
    const ReadMetaResult = IDL.Record({
        next_start_at_id: IDL.Opt(IDL.Principal),
        plots: IDL.Vec(DCityPlot),
    });
    const PlotReport = IDL.Record({
        mint: IDL.Nat32,
        time_reported: IDL.Nat32,
        reported_by: IDL.Principal,
        reason: IDL.Text,
    });
    const PlotReportResult = IDL.Record({
        total: IDL.Nat32,
        reports: IDL.Vec(PlotReport),
    });
    const Result_1 = IDL.Variant({ Ok: PlotReportResult, Err: IDL.Text });
    return IDL.Service({
        admin_adjust: IDL.Func([IDL.Vec(IDL.Nat8)], [Result], []),
        can_edit_all: IDL.Func([], [IDL.Bool], ["query"]),
        read: IDL.Func(
            [IDL.Nat32, IDL.Opt(IDL.Vec(IDL.Nat8))],
            [IDL.Opt(DCityPlot), IDL.Opt(DCityProfile), IDL.Bool],
            ["query"]
        ),
        read_activities: IDL.Func(
            [IDL.Nat32],
            [IDL.Vec(ActivityEventWithUser)],
            ["query"]
        ),
        read_meta: IDL.Func([IDL.Principal], [ReadMetaResult], ["query"]),
        read_profile: IDL.Func([], [IDL.Opt(DCityProfile)], ["query"]),
        read_reports: IDL.Func([], [Result_1], ["query"]),
        remove_report: IDL.Func([IDL.Nat32, IDL.Principal], [Result], []),
        report_meta: IDL.Func([IDL.Nat32, IDL.Text], [Result], []),
        update_plot: IDL.Func([DCityPlot], [Result], []),
        update_profile: IDL.Func([DCityProfile], [Result], []),
    });
};
