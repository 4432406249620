import { FC, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useClassToggle } from "../lib/use-class-toggle";
import { DCityPlotSummary } from "../../../store/api";

import { FilterDrawer } from "./filter-drawer";

import { CloseButton, Container, Wrap } from "./drawer.styles";
import { DrawerPlots } from "./drawer-plots";
import { DrawerActivity } from "./drawer-activity";

const DEFAULT_FILTERS = new Map<number, number[]>();
enum TABS {
    PLOTS,
    ACTIVITY,
}

export const Drawer: FC<{
    visible: boolean;
    onEnableKeyboard: (enabled: boolean) => void;
    onEdit: (nft: DCityPlotSummary) => void;
    onView: (mint: number) => void;
    onClose: () => void;
}> = ({ visible, onEnableKeyboard, onEdit, onView, onClose }) => {
    const containerRef = useClassToggle(!visible, "-translate-x-full");
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [currentTab, setCurrentTab] = useState(TABS.PLOTS);

    const onCloseHandler = () => {
        setShowFilterDrawer(false);
        onClose();
    };

    return (
        <Wrap ref={containerRef}>
            <FilterDrawer
                filters={filters}
                visible={showFilterDrawer}
                onClose={() => setShowFilterDrawer(false)}
                onChangeSearch={setFilters}
            />
            <Container>
                <div className="flex border-b border-gray-200 mb-1">
                    <div
                        role="button"
                        className={
                            "text-sm font-medium text-gray-800 -mb-px px-3 pb-1 border-b-2 " +
                            (currentTab === TABS.PLOTS
                                ? "border-[#2692C2]"
                                : "border-transparent")
                        }
                        onClick={() => setCurrentTab(TABS.PLOTS)}
                    >
                        Browser Assets
                    </div>
                    <div
                        role="button"
                        className={
                            "text-sm font-medium text-gray-800 -mb-px px-3 pb-1 border-b-2 " +
                            (currentTab === TABS.ACTIVITY
                                ? "border-[#2692C2]"
                                : "border-transparent")
                        }
                        onClick={() => setCurrentTab(TABS.ACTIVITY)}
                    >
                        Activity Feed
                    </div>
                </div>
                <CloseButton type="button" onClick={onCloseHandler}>
                    <XMarkIcon width={16} height={16} />
                </CloseButton>
                {currentTab === TABS.PLOTS && (
                    <DrawerPlots
                        filters={filters}
                        onEdit={onEdit}
                        onView={onView}
                        onEnableKeyboard={onEnableKeyboard}
                        onClearFilters={() => setFilters(DEFAULT_FILTERS)}
                        onToggleFilterDrawer={() => setShowFilterDrawer(!showFilterDrawer)}
                    />
                )}
                {currentTab === TABS.ACTIVITY && (
                    <DrawerActivity onView={onView} />
                )}
            </Container>
        </Wrap>
    );
};
