import { HttpAgent } from "@dfinity/agent";
import { DCityApiService, idlFactory } from "../../../icp/dcity_api/dcity-api.did";
import { WalletAccount } from "../../../store/api";

var cachedConnected: Promise<boolean> | null = null;

const getConnected = () => {
    if (!cachedConnected) {
        if (!window.ic?.plug) {
            cachedConnected = Promise.resolve(false);
        } else {
            cachedConnected = new Promise(async (resolve, reject) => {
                try {
                    await window.ic?.plug?.init();

                    resolve(!!window.ic?.plug?.accountId);
                } catch (e) {
                    reject(e);
                }
            });
        }
    }

    return cachedConnected;
};

export const isPlugEnabled = () => {
    return !!window.ic?.plug;
};

export const isPlugConnected = async () => {
    return getConnected();
};

export const getPlugAccounts = async (): Promise<WalletAccount[]> => {
    const plug = window.ic?.plug;

    if (plug) {
        return [
            {
                address: plug.accountId,
                name: "Plug",
            },
        ];
    }

    return [];
};

export const createPlugAgent = async (): Promise<[HttpAgent, DCityApiService]> => {
    const plug = window.ic?.plug;

    if (plug) {
        const agentCreated = await plug.createAgent({
            host: process.env.DCITY_API_HOST,
            whitelist: [
                process.env.DCITY_API_CANISTER_ID
            ],
        });

        if (agentCreated && plug.agent) {
            const service = await plug.createActor<DCityApiService>({
                host: process.env.DCITY_API_HOST,
                canisterId: process.env.DCITY_API_CANISTER_ID,
                interfaceFactory: idlFactory,
            });

            return [plug.agent, service];
        }
    }

    throw new Error("Unable to create agent");
};

export const connectPlug = async (): Promise<
    [WalletAccount[] | null, HttpAgent | null, DCityApiService | null]
> => {
    let result: WalletAccount[] | null = null;
    let agent: HttpAgent | null = null;
    let service: DCityApiService | null = null;

    try {
        const plug = window.ic?.plug;

        if (plug) {
            const connected = await getConnected();

            if (!connected) {
                await plug.requestConnect({
                    whitelist: [
                        process.env.DCITY_API_CANISTER_ID
                    ],
                });
            }

            if (plug.accountId) {
                result = [
                    {
                        address: plug.accountId,
                        name: "Plug",
                    },
                ];
                ([agent, service] = await createPlugAgent());
            }
        }
    } catch {}

    return [result, agent, service];
};

export const disconnectPlug = () => {
    window.ic?.plug?.disconnect();
};
