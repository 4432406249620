import tw from "tailwind-styled-components";

export const Container = tw.div`
    relative
    flex
    justify-center
    items-center
    bg-white
    h-20
    flex-none
    px-6
    bg-center
    bg-no-repeat
    shadow-md
    z-10
`;

export const Menu = tw.div`
    absolute
    -right-2
    top-10
    bg-white
    shadow-md
    flex-col
    items-center
    w-52
    p-2
    border
    rounded
    z-20
    hidden
    group-hover:flex
`;
