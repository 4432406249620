import { useEffect, useMemo, useState } from "react";

import { DCityPlotSummary, useNftsQuery } from "../../../store/api";
import { useWallets } from "../../wallets/lib";
import nftAttributes from "../../../icp/nft/dcity-attributes.json";

export type NftSearchResults = {
    results: DCityPlotSummary[];
    total: number;
    isFetching: boolean;
    hasWallet: boolean;
};

const DEFAULT_RESULTS: NftSearchResults = {
    results: [],
    total: 0,
    isFetching: false,
    hasWallet: false,
};

const filterSearch = (
    allRecords: DCityPlotSummary[] | undefined,
    filter: Map<number, number[]>,
    onlyOwned: boolean,
    onlyForSale: boolean,
    search: string
) => {
    const { attr: nftAttrs, data: nftsAttrData } = nftAttributes;
    const numOfAttrs = nftAttrs.length;

    search = search.toUpperCase();

    return (allRecords || []).filter((record, mint) => {
        const recordAttrs = nftsAttrData[mint];

        if (
            (onlyOwned && !record.$isOwned) ||
            (onlyForSale && record.$price === 0) ||
            (!!search && !record.$search.includes(search))
        ) {
            return false;
        }

        if (recordAttrs) {
            for (let attrIdx = 0; attrIdx < numOfAttrs; attrIdx++) {
                const filterValues = filter.get(attrIdx);
                const noFilterMatches =
                    !!filterValues &&
                    !filterValues.some((val) => recordAttrs[attrIdx] === val);

                if (noFilterMatches) {
                    return false;
                }
            }

            return true;
        }

        return false;
    });
};

export const useNftSearch = (
    pageIdx: number,
    pageSize: number,
    filter: Map<number, number[]>,
    onlyOwned: boolean,
    onlyForSale: boolean,
    search: string
): NftSearchResults => {
    const [results, setResults] = useState(DEFAULT_RESULTS);

    const { loadingWallets, accounts, connectedWallet, isAdmin } = useWallets();
    const nftArgs = useMemo<[string, boolean]>(() => {
        return [accounts.length ? accounts[0].address : "", isAdmin || false];
    }, [accounts, isAdmin]);
    const { data: nfts, isFetching } = useNftsQuery(nftArgs, {
        skip: loadingWallets,
    });
    const hasWallet = !!connectedWallet;

    useEffect(() => {
        const results = filterSearch(
            nfts,
            filter,
            onlyOwned,
            onlyForSale,
            search
        );
        const startIdx = pageIdx * pageSize;
        const endIdx = startIdx + pageSize;

        setResults({
            results: results.slice(startIdx, endIdx),
            total: results.length,
            isFetching,
            hasWallet,
        });
    }, [
        nfts,
        hasWallet,
        isFetching,
        filter,
        onlyOwned,
        onlyForSale,
        search,
        pageIdx,
        pageSize,
    ]);

    return results;
};
