import { HttpAgent } from "@dfinity/agent";
import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { createDCityActor, setAgent } from "../../../icp/dcity_api";
import { DCityApiService } from "../../../icp/dcity_api/dcity-api.did";
import { WalletAccount } from "../../../store/api";
import {
    connectPlug,
    createPlugAgent,
    disconnectPlug,
    getPlugAccounts,
    isPlugConnected,
    isPlugEnabled,
} from "./plug";
import {
    connectStoic,
    disconnectStoic,
    getStoicAccounts,
    stoicSigningIdentity,
    isStoicEnabled,
} from "./stoic";

export type SupportedWallet = "plug" | "stoic";
export type WalletContext = {
    accounts: WalletAccount[];
    connectedWallet: SupportedWallet | null | false;
    connectingWallet: SupportedWallet | null;
    loadingWallets: boolean;
    enabledWallets: Record<SupportedWallet, boolean>;
    isAdmin: boolean;
    connectWallet: (type: SupportedWallet) => Promise<void>;
    disconnectWallet: () => void;
};

const DEFAULT_WALLETS: WalletAccount[] = [];
const ENABLED_WALLETS: Record<SupportedWallet, boolean> = {
    plug: isPlugEnabled(),
    stoic: isStoicEnabled(),
};

const WalletCtx = createContext<WalletContext>({
    accounts: DEFAULT_WALLETS,
    connectedWallet: false,
    connectingWallet: null,
    loadingWallets: true,
    enabledWallets: ENABLED_WALLETS,
    isAdmin: false,
    connectWallet: async (type: SupportedWallet) => {},
    disconnectWallet: () => {},
});

const preloadAccounts = async (): Promise<
    [SupportedWallet, HttpAgent, DCityApiService | null, WalletAccount[]] | null
> => {
    const plugConnected = await isPlugConnected();
    if (plugConnected) {
        const accounts = await getPlugAccounts();

        if (accounts) {
            const [agent, service] = await createPlugAgent();

            return ["plug", agent, service, accounts];
        }
    }

    const stoicIdentity = await stoicSigningIdentity();
    if (stoicIdentity) {
        const accounts = await getStoicAccounts();

        if (accounts) {
            const agent = new HttpAgent({
                identity: stoicIdentity,
            });

            return ["stoic", agent, null, accounts];
        }
    }

    return null;
};

export const WalletContextProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const [accounts, setAccounts] = useState<WalletAccount[]>(DEFAULT_WALLETS);
    const [connectedWallet, setConnectedWallet] = useState<
        SupportedWallet | null | false
    >(false);
    const [connectingWallet, setConnectingWallet] =
        useState<SupportedWallet | null>(null);
    const [loadingWallets, setLoadingWallets] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        preloadAccounts().then(async (preload) => {
            if (preload) {
                const [nextConnected, nextAgent, nextService, nextAccounts] = preload;

                setAgent(nextAgent, nextService);
                setConnectedWallet(nextConnected);
                setAccounts(nextAccounts);

                const dcity = createDCityActor(true);
                setIsAdmin(await dcity.can_edit_all());
            } else {
                setConnectedWallet(null);
            }

            setLoadingWallets(false);
        });
    }, []);

    const connectWallet = useCallback(async (type: SupportedWallet) => {
        try {
            var nextAccounts: WalletAccount[] | null = null;
            var nextAgent: HttpAgent | null = null;
            var nextService: DCityApiService | null = null;

            setConnectingWallet(type);

            switch (type) {
                case "plug":
                    [nextAccounts, nextAgent, nextService] = await connectPlug();
                    break;
                case "stoic":
                    [nextAccounts, nextAgent] = await connectStoic();
                    break;
            }

            if (nextAccounts && nextAgent) {
                setAgent(nextAgent, nextService);
                setAccounts(nextAccounts);
                setConnectedWallet(type);

                const dcity = createDCityActor(true);
                setIsAdmin(await dcity.can_edit_all());
            } else {
                setAccounts(DEFAULT_WALLETS);
                setConnectedWallet(null);
            }
        } catch (e) {
            console.error(e);
            // TODO: Implement flash service
        } finally {
            setConnectingWallet(null);
        }
    }, []);

    const disconnectWallet = useCallback(() => {
        switch (connectedWallet) {
            case "plug":
                disconnectPlug();
                break;
            case "stoic":
                disconnectStoic();
                break;
        }

        setAgent(null, null);
        setAccounts(DEFAULT_WALLETS);
        setConnectedWallet(null);
    }, [connectedWallet]);

    return (
        <WalletCtx.Provider
            value={{
                accounts,
                connectedWallet,
                connectingWallet,
                loadingWallets,
                enabledWallets: ENABLED_WALLETS,
                isAdmin,
                connectWallet,
                disconnectWallet,
            }}
        >
            {children}
        </WalletCtx.Provider>
    );
};

export const useWallets = () => {
    return useContext(WalletCtx);
};
