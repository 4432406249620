import { FC, useCallback, useEffect, useState } from "react";

import { DCityPlotSummary, useForSaleQuery } from "../../store/api";

import { ConnectingOverlay } from "../wallets/components/connecting-overlay";
import { Drawer } from "../nfts/components/drawer";
import { EditPlot } from "../nfts/components/edit";
import { ViewPlot } from "../nfts/components/view";
import { GameHeader } from "./components/header";
import { UnityGame } from "./components/unity";

import { MainWrap } from "./index.styles";
import { SpotifyPlaylist } from "./components/spotify-playlist";
import { dcityTokenIdentifier, parseTokenMint } from "../../icp/nft";
import { useNavigate, useParams } from "react-router-dom";
import { Onboarding } from "./components/onboarding";
import { EditProfile } from "../nfts/components/edit-profile";
import { ViewReports } from "../nfts/components/view-reports";

export const GameIndex: FC = () => {
    const navigate = useNavigate();
    const { plotId } = useParams<"plotId">();
    const { data: forSalePlotIds } = useForSaleQuery();

    const [orbitToPlot, setOrbitToPlot] = useState<null | number>(null);
    const [viewingPlot, setViewingPlot] = useState<null | number>(null);
    const [editingPlot, setEditingPlot] = useState<null | number>(null);
    const [cancelKeyboard, setCancelKeyboard] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [gameLoaded, setGameLoaded] = useState(false);
    const [showingOnboarding, setShowingOnboarding] = useState(true);
    const [editingProfile, setEditingProfile] = useState(false);
    const [viewingReports, setViewingReports] = useState(false);
    const onOnboardingDone = useCallback(() => setShowingOnboarding(false), []);
    const isViewingPlot = viewingPlot !== null && viewingPlot > -1;
    const isEditingPlot = editingPlot !== null && editingPlot > -1;

    useEffect(() => {
        if (plotId && gameLoaded) {
            const mint = parseTokenMint(plotId);

            if (mint > -1) {
                setViewingPlot(mint);
                setOrbitToPlot(mint);
            }
        }
    }, [gameLoaded, plotId]);

    const onCloseEditing = (cancelled: boolean) => {
        if (isEditingPlot && !cancelled) {
            setViewingPlot(editingPlot);
            setOrbitToPlot(editingPlot);
            setEditingPlot(null);
        } else {
            setEditingPlot(null);
            setOrbitToPlot(null);
            setViewingPlot(null);
        }
    };

    const onEditPlot = (nft: DCityPlotSummary) => {
        setEditingPlot(nft.mint);
    };

    const onEditViewPlot = () => {
        setEditingPlot(viewingPlot);
        setViewingPlot(null);
    };

    const onClickPlot = (mint: number) => {
        setViewingPlot(mint);
        setOrbitToPlot(mint);

        const token = dcityTokenIdentifier(mint);
        navigate(`/${token.toText()}`, { replace: true });
    };

    const unityOnClickPlot = (idx: number) => {
        setViewingPlot(idx - 1);

        // Always set to null, this allows the orbit to always be sent to unity.
        setOrbitToPlot(null);

        const token = dcityTokenIdentifier(idx - 1);
        navigate(`/${token.toText()}`, { replace: true });
    };

    const onLoadGame = () => {
        setGameLoaded(true);
    };

    return (
        <MainWrap>
            <UnityGame
                hideLoading={showingOnboarding}
                cancelKeyboard={
                    cancelKeyboard ||
                    showingOnboarding ||
                    isViewingPlot ||
                    isEditingPlot ||
                    editingProfile
                }
                orbitToPlot={orbitToPlot}
                forSalePlots={forSalePlotIds || null}
                onClickPlot={unityOnClickPlot}
                onLoadGame={onLoadGame}
            />
            <GameHeader
                onClickHamburger={() => setShowDrawer(!showDrawer)}
                onClickEditProfile={() => setEditingProfile(true)}
                onClickViewReports={() => setViewingReports(true)}
                onClickLogo={() => setOrbitToPlot(Date.now() * -1)}
            />
            {!showingOnboarding && (
                <Drawer
                    visible={showDrawer}
                    onEnableKeyboard={setCancelKeyboard}
                    onClose={() => setShowDrawer(false)}
                    onEdit={onEditPlot}
                    onView={onClickPlot}
                />
            )}
            {viewingReports && (
                <ViewReports
                    onViewReport={setViewingPlot}
                    onClose={() => setViewingReports(false)}
                />
            )}
            {isViewingPlot && (
                <ViewPlot
                    mint={viewingPlot}
                    onEdit={onEditViewPlot}
                    onClose={() => setViewingPlot(null)}
                />
            )}
            {isEditingPlot && (
                <EditPlot mint={editingPlot} onClose={onCloseEditing} />
            )}
            {!!editingProfile && (
                <EditProfile onDone={() => setEditingProfile(false)} />
            )}
            <Onboarding onOnboardingDone={onOnboardingDone} />
            <ConnectingOverlay />
            <SpotifyPlaylist />
        </MainWrap>
    );
};
