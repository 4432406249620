import { useWallets } from "../lib";
import { WalletOverlay } from "./connectin-overlay.styles";

export const ConnectingOverlay = () => {
    const { connectingWallet } = useWallets();

    if (!connectingWallet) {
        return null;
    }

    return (
        <WalletOverlay>
            {connectingWallet === "stoic" && (
                <p>Connecting to Stoic wallet...</p>
            )}
            {connectingWallet === "plug" && <p>Connecting to Plug wallet...</p>}
        </WalletOverlay>
    );
};
