import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Principal } from "@dfinity/principal";

import {
    dcityNft,
    isOk,
    profileThumbnail,
    dcityTokenIdentifier,
} from "../icp/nft";
import {
    createDCityActor,
    accountIdBytes,
    NUM_OF_NFTS,
} from "../icp/dcity_api";
import {
    ActivityEventType,
    DCityPlot,
    DCityProfile,
    PlotReport,
} from "../icp/dcity_api/dcity-api.did";

export interface WalletAccount {
    address: string;
    name: string;
}

export type NftListingPriceByIndex = Record<number, number>;

export type NftProfile = {
    nickname: string;
    image: string | null;
    avatarMint?: number;
    avatarCollectionId?: string;
    twitterHandle: string;
};

export interface NftDetails {
    $empty: boolean;
    mint: number;
    nickname: string;
    about: string;
    price: number;
    ownerId: string | null;
    owner: NftProfile | null;
    ownerBlocked: boolean;
}

export type DCityPlotSummary = DCityPlot & {
    $isOwned: boolean;
    $search: string;
    $price: number;
};

export type DCityActivity = {
    id: number;
    type: ActivityEventType;
    timestamp: number;
    profileName: string;
    profileImage: string | null;
    mint?: number;
};

const metaCache: Map<
    string,
    Promise<Map<number, DCityPlotSummary>>
> = new Map();
let priceByMintCache: Promise<Map<number, number>> | null = null;

const resolvePriceByMint = () => {
    if (!priceByMintCache) {
        priceByMintCache = new Promise(async (resolve) => {
            try {
                const listings = await dcityNft.listings();
                const result = listings.reduce((acc, [mint, listing]) => {
                    acc.set(
                        mint,
                        Number(listing.price / BigInt(1000000)) / 100
                    );

                    return acc;
                }, new Map<number, number>());

                resolve(result);
            } catch {
                resolve(new Map<number, number>());
            }
        });
    }

    return priceByMintCache;
};

const resolveOwnedNftMints = async (account: string): Promise<Set<number>> => {
    const ownedMints = new Set<number>();

    try {
        const extResult = await dcityNft.tokens_ext(account);

        if (isOk(extResult)) {
            extResult.ok.forEach(([mint]) => {
                ownedMints.add(mint);
            });
        }
    } catch {}

    return ownedMints;
};

const lookupEntrepotMeta = async (nftPrincipal: Principal) => {
    try {
        const res = await fetch(
            `https://us-central1-entrepot-api.cloudfunctions.net/api/token/${nftPrincipal}`
        );

        const data = (await res.json()) as {
            id: string;
            owner: string;
            canister: string;
            price: number;
            time: number;
            transactions: {
                id: string;
                token: string;
                canister: string;
                time: number;
                seller: string;
                buyer: string;
                price: number;
            }[];
        };

        return data;
    } catch (e) {
        return null;
    }
};

const resolveMetaCache = (account: string, userIsAdmin: boolean) => {
    let cacheResult = metaCache.get(account);

    if (!cacheResult) {
        cacheResult = new Promise(async (resolve) => {
            const result = new Map<number, DCityPlotSummary>();

            try {
                const ownedMints = await resolveOwnedNftMints(account);
                const dcity = createDCityActor(false);
                let nextId = dcityTokenIdentifier(0);

                var tries = 0;

                while (true) {
                    const { plots, next_start_at_id } = await dcity.read_meta(
                        nextId
                    );

                    plots.forEach((plot) => {
                        result.set(plot.mint, {
                            ...plot,
                            $isOwned: userIsAdmin || ownedMints.has(plot.mint),
                            $search: buildSearch(plot.mint, plot.nickname),
                            $price: 0,
                        });
                    });

                    if (next_start_at_id[0] && ++tries < 5) {
                        nextId = next_start_at_id[0];
                    } else {
                        break;
                    }
                }

                for (let idx = 0; idx < NUM_OF_NFTS; idx++) {
                    if (!result.has(idx)) {
                        result.set(idx, {
                            $isOwned: userIsAdmin || ownedMints.has(idx),
                            $search: buildSearch(idx, ""),
                            $price: 0,
                            mint: idx,
                            about: "",
                            nickname: "",
                        });
                    }
                }
            } catch (e) {
                metaCache.delete(account);
                console.error(e);
            }

            resolve(result);
        });

        metaCache.set(account, cacheResult);
    }

    return cacheResult;
};

const buildSearch = (mint: number, title: string) => {
    return `${mint + 1} ${title}`.toUpperCase();
};

const isEmpty = (plotMeta: DCityPlot) => {
    return !(plotMeta.about || plotMeta.nickname);
};

const plotToDetails = (
    mint: number,
    price: number,
    plot?: DCityPlot,
    dcityOwnerId?: string,
    dcityOwner?: DCityProfile,
    ownerBlocked?: boolean
): NftDetails => {
    const owner: NftProfile | null = dcityOwner
        ? {
              nickname: dcityOwner.nickname,
              image: profileThumbnail(
                  dcityOwner.avatar_nft,
                  dcityOwner.avatar_mint
              ),
              twitterHandle: dcityOwner.twitter_handle,
          }
        : null;
    const ownerId = dcityOwnerId || null;

    return plot
        ? {
              $empty: isEmpty(plot),
              mint,
              about: plot.about,
              nickname: plot.nickname,
              price,
              ownerId,
              owner,
              ownerBlocked: !!ownerBlocked,
          }
        : {
              $empty: true,
              mint,
              about: "",
              nickname: "",
              price,
              ownerId,
              owner,
              ownerBlocked: !!ownerBlocked,
          };
};

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE }),
    endpoints: (builder) => ({
        reports: builder.query<{ reports: PlotReport[]; total: number }, void>({
            async queryFn() {
                const dcity = createDCityActor(true);
                const result = await dcity.read_reports();

                if (result.Err) {
                    throw new Error(result.Err);
                }

                result.Ok.reports.forEach((report) => {
                    // This is a principal from the idl
                    report.reported_by = report.reported_by.toString();
                });

                return {
                    data: result.Ok,
                };
            },
        }),
        forSale: builder.query<number[], void>({
            async queryFn() {
                const forSalePlots = await resolvePriceByMint();

                return {
                    data: Array.from(forSalePlots.keys()),
                };
            },
        }),
        activity: builder.query<DCityActivity[], number>({
            async queryFn(eventsSince) {
                const dcity = createDCityActor(false);
                const result = await dcity.read_activities(eventsSince);
                const seedId = Date.now();

                return {
                    data: result
                        .filter((x) => x.profile[0])
                        .map((x, idx) => ({
                            id: seedId + idx,
                            type: x.event_type,
                            timestamp: x.timestamp,
                            profileName: x.profile[0]
                                ? x.profile[0].nickname
                                : "",
                            profileImage: x.profile[0]
                                ? profileThumbnail(
                                      x.profile[0]?.avatar_nft,
                                      x.profile[0]?.avatar_mint
                                  )
                                : null,
                            mint: x.mint.length ? x.mint[0] : undefined,
                        }))
                        .sort((a, b) => b.timestamp - a.timestamp),
                };
            },
        }),
        nfts: builder.query<DCityPlotSummary[], [string, boolean]>({
            async queryFn([accountId, userIsAdmin]) {
                const [metaByMint, priceByMint] = await Promise.all([
                    resolveMetaCache(accountId, userIsAdmin),
                    resolvePriceByMint(),
                ]);

                const data = Array.from(metaByMint.values());
                for (let idx = 0; idx < NUM_OF_NFTS; idx++) {
                    data[idx].$price = priceByMint.get(data[idx].mint) || 0;
                }

                data.sort((a, b) => a.mint - b.mint);

                return {
                    data,
                };
            },
        }),
        nftDetails: builder.query<NftDetails | undefined, number>({
            async queryFn(mint) {
                const nftPrincipal = dcityTokenIdentifier(mint);
                const dcity = createDCityActor(false);
                const storeMeta = await lookupEntrepotMeta(nftPrincipal);
                const [[plotMeta], [ownerProfile], ownerBlocked] =
                    await dcity.read(mint, [accountIdBytes(storeMeta?.owner)]);
                const price = storeMeta ? storeMeta.price / 100000000 : 0;
                const ownerId = storeMeta?.owner;

                return {
                    data: plotToDetails(
                        mint,
                        price,
                        plotMeta,
                        ownerId,
                        ownerProfile,
                        ownerBlocked
                    ),
                };
            },
        }),
        nftUpdate: builder.mutation<DCityPlot, DCityPlot & { mint: number }>({
            async queryFn(details, queryApi, extraOptions, baseQuery) {
                const { about, nickname, mint } = details;

                const dcity = createDCityActor();

                const res = await dcity.update_plot({
                    about,
                    nickname,
                    mint,
                });

                if (res.Err) {
                    throw new Error(res.Err);
                }

                return { data: details };
            },
            async onQueryStarted(
                details,
                { dispatch, getState, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    const nftPrincipal = dcityTokenIdentifier(details.mint);
                    const storeMeta = await lookupEntrepotMeta(nftPrincipal);
                    const { queries } = getState().api;
                    const entryKeys = Object.keys(queries).filter((queryKey) =>
                        queryKey.startsWith("nfts(")
                    );
                    const price = storeMeta ? storeMeta.price / 100000000 : 0;

                    dispatch(
                        api.util.updateQueryData(
                            "nftDetails",
                            details.mint,
                            (existing) => {
                                const result = plotToDetails(
                                    details.mint,
                                    price,
                                    data
                                );

                                if (existing && existing.owner) {
                                    result.owner = existing.owner;
                                }

                                if (existing && existing.ownerId) {
                                    result.ownerId = existing.ownerId;
                                }

                                return result;
                            }
                        )
                    );

                    entryKeys.forEach((entryKey) => {
                        const entry = queries[entryKey];

                        if (!entry) {
                            return;
                        }

                        dispatch(
                            api.util.updateQueryData(
                                "nfts",
                                entry.originalArgs as [string, boolean],
                                (existing) => {
                                    const idx = existing.findIndex(
                                        (item) => item.mint === details.mint
                                    );

                                    if (idx > -1) {
                                        existing[idx] = {
                                            ...existing[idx],
                                            ...data,
                                        };
                                    }
                                }
                            )
                        );
                    });
                } catch {}
            },
        }),
        readProfile: builder.query<NftProfile | undefined, void>({
            async queryFn() {
                const dcity = createDCityActor(false);
                const [profile] = await dcity.read_profile();

                if (profile) {
                    const [collectionId] = profile.avatar_nft;
                    const [avatarMint] = profile.avatar_mint;

                    return {
                        data: {
                            nickname: profile.nickname,
                            image: profileThumbnail(
                                profile.avatar_nft,
                                profile.avatar_mint
                            ),
                            avatarCollectionId: collectionId
                                ? collectionId.toString()
                                : undefined,
                            avatarMint:
                                typeof avatarMint === "number"
                                    ? avatarMint
                                    : undefined,
                            twitterHandle: profile.twitter_handle,
                        },
                    };
                }

                return { data: undefined };
            },
        }),
        profileUpdate: builder.mutation<
            NftProfile & { id: string },
            NftProfile & { id: string }
        >({
            async queryFn(profile, queryApi, extraOptions, baseQuery) {
                const {
                    nickname: name,
                    avatarMint,
                    avatarCollectionId,
                    twitterHandle,
                } = profile;

                const dcity = createDCityActor();

                const res = await dcity.update_profile({
                    nickname: name,
                    avatar_nft: avatarCollectionId
                        ? [Principal.fromText(avatarCollectionId)]
                        : [],
                    avatar_mint:
                        typeof avatarMint === "number" ? [avatarMint] : [],
                    twitter_handle: twitterHandle,
                });

                if (res.Err) {
                    throw new Error(res.Err);
                }

                return { data: profile };
            },
            async onQueryStarted(
                details,
                { dispatch, getState, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    const { queries } = getState().api;
                    const entryKeys = Object.keys(queries).filter((queryKey) =>
                        queryKey.startsWith("nftDetails(")
                    );
                    const myAccountId = data.id;

                    dispatch(
                        api.util.updateQueryData(
                            "readProfile",
                            undefined,
                            () => data
                        )
                    );

                    entryKeys.forEach((entryKey) => {
                        const entry = queries[entryKey];
                        const details = entry?.data as NftDetails | undefined;

                        if (
                            entry &&
                            details &&
                            details.ownerId === myAccountId
                        ) {
                            dispatch(
                                api.util.updateQueryData(
                                    "nftDetails",
                                    entry.originalArgs as number,
                                    (existing) => {
                                        if (existing) {
                                            existing.owner = data;
                                        }

                                        return existing;
                                    }
                                )
                            );
                        }
                    });
                } catch {}
            },
        }),
    }),
});

export const {
    useActivityQuery,
    useForSaleQuery,
    useNftDetailsQuery,
    useNftsQuery,
    useNftUpdateMutation,
    useProfileUpdateMutation,
    useReadProfileQuery,
    useReportsQuery,
} = api;
