import tw from "tailwind-styled-components";

export const Viewport = tw.div`
    relative
    flex-grow
`;

export const Canvas = tw.canvas`
    select-none
    bg-black
    absolute
    inset-0
    w-full
    h-full
`;

export const LoadingContainer = tw.div`
    absolute
    inset-0
    flex
    flex-col
    justify-center
    items-center
    space-y-6
`;
export const LoadingBar = tw.div`
    w-1/4
    h-6
    rounded-md
    border-2
    border-opacity-40
    border-white
    overflow-hidden
`;
export const LoadingBarProgress = tw.div`
    h-full
    bg-white
    transition-all
    duration-300
`;
