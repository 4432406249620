import tw from "tailwind-styled-components";

export const Wrap = tw.div`
    flex
    flex-col
    absolute
    left-0
    top-20
    bottom-0
    w-96
    transition-transform
    duration-300
    -translate-x-full
    p-6
`;

export const Container = tw.div`
    relative
    flex
    flex-col
    p-3
    bg-white
    h-full
    rounded-lg
`;

export const CloseButton = tw.button`
    absolute
    right-2.5
    top-2.5
    text-gray-800
    p-1
`;
