import tw from "tailwind-styled-components";

export const WalletOverlay = tw.div`
    flex
    justify-center
    items-center
    fixed
    inset-0
    bg-opacity-80
    bg-black
    text-2xl
    text-white
    !m-0
`;
