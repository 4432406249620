import {
    FC,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { LoadingAnimation } from "../../../components/loading-animation";
import splitFiles from "../../../assets/splits.json";

import {
    Canvas,
    LoadingBar,
    LoadingBarProgress,
    LoadingContainer,
    Viewport,
} from "./unity.styles";

type UnityInstance = {
    Quit: () => void;
    SendMessage: (gameObject: string, objFn: string, ...args: any[]) => void;
};

const unityRef: {
    tag: HTMLScriptElement | null;
    current: UnityInstance | null;
} = {
    tag: null,
    current: null,
};

type SPLIT_FILE = { chunks: [number, string][]; total: number };
const SPLIT_FILES = splitFiles as unknown as Record<string, SPLIT_FILE>;

// Replace in Build.loader.js "new XMLHttpRequest" with "new UnityWrappedNetwork"
class UnityWrappedNetwork {
    private base: XMLHttpRequest;
    private loadEvent?: (
        this: XMLHttpRequest,
        ev: ProgressEvent<XMLHttpRequestEventTarget>
    ) => any;
    private progressEvent?: (
        this: XMLHttpRequest,
        ev: ProgressEvent<XMLHttpRequestEventTarget>
    ) => any;
    private splitFile?: SPLIT_FILE;
    private splitResult?: ArrayBuffer;

    constructor() {
        this.base = new XMLHttpRequest();
        this.base.addEventListener("load", this.onLoad);
        this.base.addEventListener("progress", this.onProgress);
    }

    private onLoad = (ev: ProgressEvent<XMLHttpRequestEventTarget>) => {
        if (this.loadEvent) {
            this.loadEvent.apply(this.base, [ev]);
        }
    };

    private onProgress = (ev: ProgressEvent<XMLHttpRequestEventTarget>) => {
        if (this.progressEvent) {
            this.progressEvent.apply(this.base, [ev]);
        }
    };

    private downloadContent = async (url: string): Promise<ArrayBuffer> => {
        for (var i = 0; i < 4; i++) {
            try {
                const res = await fetch(url);
                const content = await res.arrayBuffer();

                return content;
            } catch {
                console.log(`Error downloading ${url}, try ${i + 1}`);
            }
        }

        throw new Error(`Unable to download ${url}, ran out of retries`);
    };

    get response() {
        if (this.splitResult) {
            return this.splitResult;
        }

        return this.base.response;
    }

    get responseType() {
        return this.base.responseType;
    }

    set responseType(value: XMLHttpRequestResponseType) {
        this.base.responseType = value;
    }

    addEventListener<K extends keyof XMLHttpRequestEventMap>(
        type: K,
        listener: (this: XMLHttpRequest, ev: XMLHttpRequestEventMap[K]) => any,
        options?: boolean | AddEventListenerOptions
    ) {
        if (type === "load") {
            this.loadEvent = listener;
        } else if (type === "progress") {
            this.progressEvent = listener;
        } else {
            this.base.addEventListener(type, listener, options);
        }
    }

    open(method: string, url: string) {
        this.splitFile = SPLIT_FILES[url];

        if (!this.splitFile) {
            this.base.open(method, url);
        }
    }

    async send(body?: Document | XMLHttpRequestBodyInit | null | undefined) {
        if (this.splitFile) {
            const { chunks, total } = this.splitFile;
            const combined = new Uint8Array(total);
            const streams: Promise<void>[] = [];

            for (var loaded = 0, streamIdx = 0; streamIdx < 3; streamIdx++) {
                streams.push(
                    // eslint-disable-next-line no-loop-func
                    new Promise(async (resolve) => {
                        while (chunks.length) {
                            const [offset, url] = chunks.shift() as [
                                number,
                                string
                            ];
                            const content = await this.downloadContent(url);

                            combined.set(new Uint8Array(content), offset);
                            loaded += content.byteLength;

                            if (this.progressEvent) {
                                this.progressEvent.apply(this.base, [
                                    {
                                        type: "progress",
                                        loaded,
                                        lengthComputable: true,
                                        total,
                                    } as any,
                                ]);
                            }
                        }

                        resolve();
                    })
                );
            }

            await Promise.all(streams);

            this.splitResult = combined.buffer;

            if (this.loadEvent) {
                this.loadEvent.apply(this.base, [{} as any]);
            }
        } else {
            this.base.send(body);
        }
    }
}

window.UnityWrappedNetwork = UnityWrappedNetwork as any;

async function loadUnityScript() {
    if (!unityRef.tag) {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "/build/build.loader.js";
            script.onerror = reject;
            script.onload = resolve;

            unityRef.tag = script;
            document.body.appendChild(script);
        });
    }
}

const handleCanvasRef =
    (
        setLoadingProgress: React.Dispatch<React.SetStateAction<number>>,
        setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
    ) =>
    async (node: HTMLCanvasElement | null) => {
        if (node && !unityRef.current) {
            await loadUnityScript();

            const config = {
                dataUrl: "/build/build.data.unityweb",
                frameworkUrl: "/build/build.framework.js.unityweb",
                codeUrl: "/build/build.wasm.unityweb",
                streamingAssetsUrl: "/StreamingAssets",
                productVersion: "0.1",
                showBanner: (
                    msg: string,
                    type: "error" | "warning" | "info"
                ) => {},
            };

            createUnityInstance(node, config, setLoadingProgress).then(
                (unityInstance: UnityInstance) => {
                    unityRef.current = unityInstance;

                    setIsLoaded(true);
                }
            );
        } else if (!node && unityRef.current) {
            unityRef.current?.Quit();
            unityRef.current = null;
        }
    };

export const UnityGame: FC<{
    hideLoading: boolean;
    cancelKeyboard: boolean;
    orbitToPlot: number | null;
    forSalePlots: number[] | null;
    onClickPlot: (plotIdx: number) => void;
    onLoadGame: () => void;
}> = ({
    hideLoading,
    cancelKeyboard,
    orbitToPlot,
    forSalePlots,
    onClickPlot,
    onLoadGame,
}) => {
    const cancelKeyboardRef = useRef(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const ref = useMemo(
        () => handleCanvasRef(setLoadingProgress, setIsLoaded),
        [setLoadingProgress, setIsLoaded]
    );

    useEffect(() => {
        if (isLoaded) {
            onLoadGame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    useEffect(() => {
        if (orbitToPlot !== null && isLoaded && unityRef.current) {
            if (orbitToPlot < 0) {
                unityRef.current.SendMessage("TouchCamera", "ReturnToStart");
            } else {
                unityRef.current.SendMessage(
                    "TouchCamera",
                    "FocusOnBuilding",
                    orbitToPlot + 1
                );
            }
        }
    }, [isLoaded, orbitToPlot]);

    useEffect(() => {
        if (forSalePlots && isLoaded && unityRef.current) {
            unityRef.current.SendMessage(
                "TouchCamera",
                "BuildingsForSale",
                forSalePlots.map((x) => x + 1).join(",")
            );
        }
    }, [isLoaded, forSalePlots]);

    useLayoutEffect(() => {
        window.unityOnFocusBuilding = onClickPlot;
    }, [onClickPlot]);

    useLayoutEffect(() => {
        cancelKeyboardRef.current = cancelKeyboard;
    }, [cancelKeyboard]);

    // Allows editing plot, unity will attempt to capture and prevent keyboard events
    useLayoutEffect(() => {
        const orig = window.addEventListener;

        window.addEventListener = (
            type: string,
            listener: any,
            options?: boolean | AddEventListenerOptions
        ) => {
            if (
                (type === "keydown" ||
                    type === "keyup" ||
                    type === "keypress") &&
                listener.name === "jsEventHandler"
            ) {
                orig.call(
                    window,
                    type,
                    (e) => {
                        if (!cancelKeyboardRef.current) {
                            listener(e);
                        }
                    },
                    options
                );
            } else {
                return orig.call(window, type, listener, options);
            }
        };

        return () => {
            window.addEventListener = orig;
        };
    }, []);

    return (
        <Viewport>
            <Canvas ref={ref} />
            {!hideLoading && !isLoaded && (
                <LoadingContainer>
                    <LoadingAnimation />
                    <LoadingBar>
                        <LoadingBarProgress
                            style={{
                                width: `${(loadingProgress * 100).toFixed(2)}%`,
                            }}
                        />
                    </LoadingBar>
                </LoadingContainer>
            )}
        </Viewport>
    );
};
