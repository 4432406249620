import { FC } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline"

export interface SelectFieldOption {
    label: string;
    value: string;
}

export const SelectField: FC<{
    label: string;
    options: SelectFieldOption[];
    value: string | number;
    allowEmpty?: boolean;
    className?: string;
    containerClassName?: string;
    placeholder?: string;
    autoFocus?: boolean;
    onChange: (newValue: string) => void;
}> = ({
    label,
    options,
    value,
    allowEmpty,
    className,
    containerClassName,
    placeholder,
    autoFocus,
    onChange,
}) => {
    return (
        <label className={"relative block mb-4 " + (containerClassName || "")}>
            <div className="text-sm font-medium mb-0.5 mx-3">{label}</div>
            <select
                value={value}
                className={
                    "absolute inset-x-0 bottom-0 appearance-none bg-transparent rounded-full text-sm py-2 px-3 pr-12 h-10 placeholder:text-neutral-500 " +
                    (className || "")
                }
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={(e) => onChange(e.target.value)}
            >
                {!!allowEmpty && <option />}
                {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
            <div className="flex justify-end py-2 px-3 rounded-full bg-gray-100">
                <ChevronDownIcon className="h-6 w-6" />
            </div>
        </label>
    );
};
