import { Actor, HttpAgent } from "@dfinity/agent";
import { DCityApiService, idlFactory } from "./dcity-api.did";

let currentAgent: HttpAgent | null = null;
let currentService: DCityApiService | null = null;

export const NUM_OF_NFTS = 2500;

export const accountIdBytes = (accountId: string | undefined): number[] => {
    if (!accountId || accountId.length !== 64) {
        throw new Error("Invalid accountId");
    }
    const bytes: number[] = new Array(32);

    for (var i = 0, x = 0; i < 64; i += 2, x++) {
        bytes[x] = parseInt(accountId.slice(i, i + 2), 16);
    }

    return bytes;
};

export const setAgent = (agent: HttpAgent | null, service: DCityApiService | null) => {
    currentAgent = agent;
    currentService = service;
};

export const createDCityActor = (globalAgentRequired: boolean = true) => {
    if (!currentAgent && globalAgentRequired) {
        throw new Error("Agent has not been set yet.");
    }

    const agent =
        currentAgent ||
        new HttpAgent({
            host: process.env.DCITY_API_HOST,
        });

    if (process.env.REACT_APP_DFX_NETWORK !== "ic") {
        agent.fetchRootKey().catch((err) => {
            console.warn(
                "Unable to fetch root key. Check to ensure that your local replica is running"
            );
            console.error(err);
        });
    }

    if (currentService) {
        return currentService;
    }

    return Actor.createActor<DCityApiService>(idlFactory, {
        agent,
        canisterId: process.env.DCITY_API_CANISTER_ID,
    });
};
