import { FC, HTMLAttributes } from "react";
import tw from "tailwind-styled-components";

export const ModalBackdrop = tw.div`
    bg-opacity-30
    bg-black
    fixed
    inset-0
    flex
    justify-center
    items-center
    p-4
    pt-24
    overflow-auto
`;

type ModalContentProps = {
    $empty?: boolean
} & HTMLAttributes<HTMLDivElement>

export const ModalContent = tw.div`
    relative
    flex
    flex-col
    bg-white
    rounded-2xl
    p-6
    my-auto

    ${({ $empty }: ModalContentProps) => $empty ? '' : 'w-full max-w-2xl'}
` as FC<ModalContentProps>;

export const CloseButton = tw.button`
    absolute
    right-6
    top-6
`;
