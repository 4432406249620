import { FC, HTMLInputTypeAttribute } from "react";

export const TextField: FC<{
    label: string;
    type?: HTMLInputTypeAttribute;
    className?: string;
    containerClassName?: string;
    placeholder?: string;
    maxLength: number;
    value: string | number;
    autoFocus?: boolean;
    onChange: (newValue: string) => void;
}> = ({
    label,
    type,
    value,
    className,
    containerClassName,
    placeholder,
    maxLength,
    autoFocus,
    onChange,
}) => {
    return (
        <label className={"block mb-4 " + (containerClassName || "")}>
            <div className="text-sm font-medium mb-0.5 mx-3">{label}</div>
            <input
                type={type}
                value={value}
                maxLength={maxLength}
                className={
                    "bg-gray-100 rounded-full text-sm p-2 px-3 h-10 placeholder:text-neutral-500 " +
                    (className || "")
                }
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={(e) => onChange(e.target.value)}
            />
        </label>
    );
};
