import { FC, PropsWithChildren, useMemo } from "react";
import { CSSProperties } from "styled-components";

import { tokenThumbnail } from "../../../icp/nft";

export const DCityImage: FC<
    PropsWithChildren<{ mint: number; className?: string }>
> = ({ mint, className, children }) => {
    const imageStyle = useMemo<CSSProperties>(() => {
        return { backgroundImage: `url(${tokenThumbnail(mint)})` };
    }, [mint]);

    return (
        <div
            className={
                "aspect-square bg-center bg-cover rounded-xl overflow-hidden " +
                (className || "")
            }
            style={imageStyle}
        >
            {children}
        </div>
    );
};
