import { FC, FormEventHandler, useEffect, useMemo, useState } from "react";
import { Principal } from "@dfinity/principal";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/buttons";
import {
    SelectField,
    SelectFieldOption,
} from "../../../components/select-field";
import { TextField } from "../../../components/text-field";
import {
    isErr,
    profileThumbnail,
    resolveActor,
    VIBESTER_PRINCIPAL,
    VIBESTER_PRINCIPAL_ID,
} from "../../../icp/nft";
import { useWallets } from "../../wallets/lib";
import {
    useProfileUpdateMutation,
    useReadProfileQuery,
} from "../../../store/api";

import { ErrorAlert } from "./edit-profile.styles";

type NftImage = {
    mint: number;
    url: string;
};

const useQueryNftCollection = (
    collectionId: string,
    primaryAccount: string
) => {
    const [loaded, setLoaded] = useState(false);
    const [results, setResults] = useState<NftImage[]>([]);

    useEffect(() => {
        if (collectionId && primaryAccount) {
            setLoaded(false);

            resolveActor(collectionId)
                .tokens_ext(primaryAccount)
                .then((result) => {
                    setLoaded(true);

                    if (isErr(result)) {
                        setResults([]);
                    } else {
                        setResults(
                            result.ok.map(([mint]) => ({
                                mint,
                                url: profileThumbnail(
                                    [VIBESTER_PRINCIPAL],
                                    [mint]
                                ) as string,
                            }))
                        );
                    }
                });
        } else {
            setLoaded(false);
            setResults([]);
        }
    }, [collectionId, primaryAccount]);

    return { loaded, results };
};

const PAGE_SIZE = 7;

const COLLECTION_META: Record<string, { name: string; marketplace: string }> = {
    [VIBESTER_PRINCIPAL_ID]: {
        name: "Vibester",
        marketplace: "https://entrepot.app/marketplace/vibesters",
    },
};

const COLLECTIONS: SelectFieldOption[] = Object.entries(COLLECTION_META).map(
    ([value, { name: label }]) => ({
        label,
        value,
    })
);

export const EditProfileForm: FC<{
    saveBtnText: string;
    onSave: () => void;
    onCancel?: () => void;
}> = ({ saveBtnText, onSave, onCancel }) => {
    const [hasError, setHasError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [nickname, setNickname] = useState("");
    const [twitterHandle, setTwitterHandle] = useState("");
    const [avatarCollection, setAvatarCollection] = useState("");
    const [avatarMint, setAvatarMint] = useState(-1);
    const { accounts } = useWallets();
    const primaryAccount = accounts[0]?.address;
    const { loaded, results } = useQueryNftCollection(
        avatarCollection,
        primaryAccount
    );
    const [nftPageIdx, setNftPageIdx] = useState(0);
    const maxNftPageIdx = useMemo(
        () => Math.floor(results.length / PAGE_SIZE),
        [results]
    );
    const pagesResults = useMemo(
        () =>
            results.slice(
                PAGE_SIZE * nftPageIdx,
                PAGE_SIZE * nftPageIdx + PAGE_SIZE
            ),
        [results, nftPageIdx]
    );
    const [updateProfile] = useProfileUpdateMutation();
    const { isLoading, currentData } = useReadProfileQuery();
    const isNftCollectionEmpty = loaded && !results.length;

    useEffect(() => {
        if (loaded && avatarMint > -1) {
            const mintIdx = results.findIndex(x => x.mint === avatarMint);

            if (mintIdx > -1) {
                setNftPageIdx(Math.floor(mintIdx / PAGE_SIZE));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, !!currentData]);

    useEffect(() => {
        if (currentData) {
            setNickname(currentData.nickname);
            setTwitterHandle(currentData.twitterHandle);

            if (currentData.avatarCollectionId) {
                setAvatarCollection(currentData.avatarCollectionId);
            }

            if (currentData.avatarMint !== undefined) {
                setAvatarMint(currentData.avatarMint);
            }
        }
    }, [currentData]);

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        try {
            setHasError(false);
            setSaving(true);

            await updateProfile({
                id: primaryAccount,
                nickname,
                image: profileThumbnail(
                    avatarCollection
                        ? [Principal.fromText(avatarCollection)]
                        : [],
                    avatarMint > -1 ? [avatarMint] : []
                ),
                avatarCollectionId: avatarCollection || undefined,
                avatarMint: avatarMint > -1 ? avatarMint : undefined,
                twitterHandle,
            }).unwrap();

            onSave();
        } catch {
            setHasError(true);
            setSaving(false);
        }
    };

    if (isLoading) {
        return <div>Loading Your Profile</div>;
    }

    return (
        <form onSubmit={onSubmit}>
            <TextField
                label="Your Nickname"
                value={nickname}
                maxLength={40}
                autoFocus
                containerClassName="flex flex-col flex-1"
                onChange={setNickname}
            />
            <TextField
                label="Your Twitter Handle"
                value={twitterHandle}
                maxLength={20}
                containerClassName="flex flex-col flex-1"
                onChange={(val) =>
                    setTwitterHandle(val.replace(/[^a-z0-9_]/gi, ""))
                }
            />
            <SelectField
                label="Select a nft collection to use as an avatar"
                allowEmpty
                containerClassName="flex flex-col flex-1"
                options={COLLECTIONS}
                value={avatarCollection}
                onChange={setAvatarCollection}
            />
            <div
                className={`flex flex-wrap mb-3 h-16 ${
                    isNftCollectionEmpty ? "justify-center items-center" : ""
                }`}
            >
                {loaded &&
                    !!avatarCollection &&
                    (isNftCollectionEmpty ? (
                        <>
                            Looks like you don't own any{" "}
                            {COLLECTION_META[avatarCollection].name} nfts, go
                            check it out at
                            <a
                                href={
                                    COLLECTION_META[avatarCollection]
                                        .marketplace
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500 ml-1"
                            >
                                Entrepot
                            </a>
                        </>
                    ) : (
                        <>
                            <button
                                type="button"
                                className={
                                    "w-10 h-16 mr-3 rounded-md flex justify-center items-center " +
                                    (nftPageIdx === 0
                                        ? "bg-gray-100 text-gray-200"
                                        : "bg-gray-200 text-gray-800")
                                }
                                disabled={nftPageIdx === 0}
                                onClick={() =>
                                    setNftPageIdx(Math.max(0, nftPageIdx - 1))
                                }
                            >
                                <ChevronLeftIcon
                                    strokeWidth="2"
                                    width={36}
                                    height={32}
                                />
                            </button>
                            {pagesResults.map((result) => (
                                <img
                                    key={result.mint}
                                    src={result.url}
                                    className={`w-16 h-16 rounded-md cursor-pointer mr-3 ${
                                        avatarMint === result.mint
                                            ? "outline outline-2"
                                            : ""
                                    }`}
                                    onClick={() => setAvatarMint(result.mint)}
                                    alt="Nft"
                                />
                            ))}
                            <button
                                type="button"
                                className={
                                    "w-10 h-16 rounded-md flex justify-center items-center " +
                                    (nftPageIdx === maxNftPageIdx
                                        ? "bg-gray-100 text-gray-200"
                                        : "bg-gray-200 text-gray-800")
                                }
                                disabled={nftPageIdx === maxNftPageIdx}
                                onClick={() =>
                                    setNftPageIdx(
                                        Math.min(maxNftPageIdx, nftPageIdx + 1)
                                    )
                                }
                            >
                                <ChevronRightIcon
                                    strokeWidth="2"
                                    width={36}
                                    height={32}
                                />
                            </button>
                        </>
                    ))}
            </div>
            {hasError && (
                <ErrorAlert>
                    There was a problem saving your profile, refresh the page
                    and try again.
                </ErrorAlert>
            )}
            <div className="flex justify-end space-x-4">
                {!!onCancel && (
                    <Button
                        disabled={saving}
                        $rounded
                        $medium
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                )}
                <Button disabled={saving} $primary $rounded $medium>
                    {saveBtnText}
                </Button>
            </div>
        </form>
    );
};
