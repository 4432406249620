import { FC } from "react";

export const ToggleButton: FC<{
    label: string;
    checked: boolean;
    onChange: (newValue: boolean) => void;
}> = ({ label, checked, onChange }) => {
    return (
        <div
            className="flex justify-between mb-2"
            role="button"
            onClick={() => onChange(!checked)}
        >
            <div className="text-xs text-gray-900">{label}</div>
            <div className="duration-150 transition-colors h-6 w-12 rounded-full p-px bg-gray-100">
                <div
                    className={
                        "w-4 h-4 rounded-full m-0.5 duration-150 transition-all " +
                        (checked ? "bg-primary translate-x-6" : "bg-neutral-500")
                    }
                />
            </div>
        </div>
    );
};
