import { FC, useState, useEffect, FormEventHandler, useMemo } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useNftDetailsQuery, useNftUpdateMutation } from "../../../store/api";
import { dcityTokenIdentifier } from "../../../icp/nft";
import { currency } from "../../../util/formatters";

import { TextField } from "../../../components/text-field";
import { DCityImage } from "./dcity-image";
import { MultiTextField } from "../../../components/multi-text-field";
import { Button } from "../../../components/buttons";
import { LoadingAnimation } from "../../../components/loading-animation";
import { icp } from "../../../assets";

import {
    CloseButton,
    ErrorAlert,
    ModalActions,
    ModalBackdrop,
    ModalContent,
} from "./edit.styles";

export const EditPlot: FC<{
    mint: number;
    onClose: (cancelled: boolean) => void;
}> = ({ mint, onClose }) => {
    const [saving, setSaving] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [nickname, setNickname] = useState("");
    const [about, setAbout] = useState("");
    const { data: nftDetails } = useNftDetailsQuery(mint);
    const [updatePlot] = useNftUpdateMutation();
    const tokenId = useMemo(() => dcityTokenIdentifier(mint).toText(), [mint]);

    useEffect(() => {
        if (nftDetails) {
            setNickname(nftDetails.nickname);
            setAbout(nftDetails.about);
        }
    }, [nftDetails]);

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        try {
            setHasError(false);
            setSaving(true);

            await updatePlot({
                mint,
                about,
                nickname,
            }).unwrap();

            onClose(false);
        } catch {
            setHasError(true);
            setSaving(false);
        }
    };

    return (
        <ModalBackdrop>
            {nftDetails ? (
                <ModalContent>
                    <form onSubmit={onSubmit}>
                        <div className="flex space-x-6 mb-6">
                            <DCityImage
                                mint={mint}
                                className="w-28 rounded-3xl"
                            />
                            <div>
                                <p className="text-base font-medium mb-2">
                                    Plot #{mint + 1}
                                </p>
                                {nftDetails.price ? (
                                    <a
                                        href={`https://entrepot.app/marketplace/asset/${tokenId}`}
                                        className="inline-flex space-x-1 items-center text-sm text-gray-600"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={icp}
                                            alt="ICP"
                                            className="w-4 h-4"
                                        />
                                        <span>
                                            {currency(nftDetails.price)}
                                        </span>
                                    </a>
                                ) : (
                                    <p className="text-xs text-neutral-500">
                                        Unlisted
                                    </p>
                                )}
                            </div>
                        </div>
                        <TextField
                            label="Plot Nickname"
                            value={nickname}
                            maxLength={40}
                            autoFocus
                            containerClassName="flex flex-col flex-1"
                            onChange={setNickname}
                        />
                        <MultiTextField
                            label="About"
                            className="block w-full h-24"
                            value={about}
                            maxLength={600}
                            onChange={setAbout}
                        />
                        {hasError && (
                            <ModalActions>
                                <ErrorAlert>
                                    There was a problem saving the Plot, refresh
                                    the page and try again.
                                </ErrorAlert>
                            </ModalActions>
                        )}
                        <ModalActions>
                            <Button
                                type="button"
                                $outline
                                $secondary
                                $small
                                $rounded
                                disabled={saving}
                                onClick={() => onClose(true)}
                            >
                                Cancel
                            </Button>
                            <Button $primary $small $rounded disabled={saving}>
                                {saving ? "Saving..." : "Save Changes"}
                            </Button>
                        </ModalActions>
                        <CloseButton type="button" onClick={onClose}>
                            <XMarkIcon width={20} height={20} />
                        </CloseButton>
                    </form>
                </ModalContent>
            ) : (
                <LoadingAnimation />
            )}
        </ModalBackdrop>
    );
};
