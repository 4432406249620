import type { InterfaceFactory } from "@dfinity/candid/lib/cjs/idl";
import type { Principal } from "@dfinity/principal";

// Generate IDL from: https://a4gq6-oaaaa-aaaab-qaa4q-cai.raw.ic0.app/?id=gtb2b-tiaaa-aaaah-qcxca-cai

interface Listing {
    locked: number[];
    seller: Principal;
    price: bigint;
}

export type CommonError = { InvalidToken?: string; Other?: string };

export type OkResult = {
    ok: Array<[number, Listing[], number[][]]>;
};
export type ErrResult = { err: CommonError };
export type Result = OkResult | ErrResult;

export interface NftService {
    tokens_ext: (accountId: string) => Promise<Result>;
    listings: () => Promise<Array<[number, Listing]>>;
}

export const idlFactory: InterfaceFactory = ({ IDL }) => {
    const Blob = IDL.Vec(IDL.Nat8);
    const TokenIndex = IDL.Nat32;
    const Time = IDL.Int;
    const Listing = IDL.Record({
        locked: IDL.Opt(Time),
        seller: IDL.Principal,
        price: IDL.Nat64,
    });

    const Metadata = IDL.Variant({
        fungible: IDL.Record({
            decimals: IDL.Nat8,
            metadata: IDL.Opt(Blob),
            name: IDL.Text,
            symbol: IDL.Text,
        }),
        nonfungible: IDL.Record({ metadata: IDL.Opt(Blob) }),
    });

    const Result = IDL.Variant({
        ok: IDL.Vec(IDL.Tuple(TokenIndex, IDL.Opt(Listing), IDL.Opt(Blob))),
        err: IDL.Variant({
            InvalidToken: IDL.Text,
            Other: IDL.Text,
        }),
    });

    return IDL.Service({
        tokens_ext: IDL.Func([IDL.Text], [Result], ["query"]),
        listings: IDL.Func(
            [],
            [IDL.Vec(IDL.Tuple(TokenIndex, Listing, Metadata))],
            ["query"]
        ),
    });
};
