import { FC, useEffect, useState } from "react";

import { welcome00Splash, welcome00SplashDcity } from "../../../assets";
import { Button } from "../../../components/buttons";
import { ConnectWallets } from "../../wallets/components/connect";
import { useWallets } from "../../wallets/lib";
import { EditProfileForm } from "../../nfts/components/edit-profile-form";
import {
    Announcement,
    ANNOUNCEMENTS,
    OnboardingAnnouncements,
} from "./onboarding-announcements";

function getSeenAnnouncements(): string[] {
    try {
        const value = localStorage.getItem("seen-onboarding");

        if (value) {
            return JSON.parse(value) as string[];
        }
    } catch {}
    return [];
}

export const Onboarding: FC<{
    onOnboardingDone: () => void;
}> = ({ onOnboardingDone }) => {
    const { loadingWallets, connectingWallet, connectedWallet } = useWallets();
    const [onboardingStep, setOnboardingStep] = useState<
        "init" | "welcome" | "connect" | "profile" | "announcements" | "done"
    >("init");
    const [unSeenAnnouncements, setUnSeenAnnouncements] = useState<
        Announcement[]
    >(() => {
        const seenOnboarding = getSeenAnnouncements();

        return ANNOUNCEMENTS.filter((x) => !seenOnboarding.includes(x.id));
    });

    useEffect(() => {
        if (!loadingWallets) {
            if (onboardingStep === "init") {
                if (localStorage.getItem("has-profile") === "true") {
                    setOnboardingStep("announcements");
                } else {
                    setOnboardingStep("welcome");
                }
            } else if (connectedWallet && onboardingStep === "connect") {
                setOnboardingStep("profile");
            }
        }
    }, [loadingWallets, connectedWallet, onboardingStep]);

    useEffect(() => {
        if (onboardingStep === "announcements" && !unSeenAnnouncements.length) {
            setOnboardingStep("done");
            onOnboardingDone();
        }
    }, [onboardingStep, unSeenAnnouncements.length, onOnboardingDone]);

    const onClickContinueAsGuest = () => {
        localStorage.setItem("as-guest", "true");
        setOnboardingStep("announcements");
    };

    const onDoneAnnouncements = () => {
        const seenOnboarding = getSeenAnnouncements();

        unSeenAnnouncements.forEach(({ id }) => {
            if (!seenOnboarding.includes(id)) {
                seenOnboarding.push(id);
            }
        });

        localStorage.setItem("seen-onboarding", JSON.stringify(seenOnboarding));

        setUnSeenAnnouncements([]);
    };

    if (!loadingWallets && !connectingWallet && onboardingStep !== "done") {
        return onboardingStep === "announcements" ? (
            <OnboardingAnnouncements
                announcements={unSeenAnnouncements}
                onDone={onDoneAnnouncements}
            />
        ) : (
            <div className="absolute inset-0 flex justify-center items-center z-20">
                <div
                    className="flex flex-col p-6 rounded-2xl shadow-lg"
                    style={{
                        backgroundImage: `url(${welcome00Splash})`,
                        backgroundSize: "536px 389px",
                        backgroundPosition: "center center",
                        width: 536,
                        height: 389,
                    }}
                >
                    {onboardingStep !== "profile" && (
                        <div className="space-y-2 mb-4">
                            <div className="uppercase text-center text-white font-black text-lg">
                                Welcome To
                            </div>
                            <img
                                src={welcome00SplashDcity}
                                alt="DCity"
                                className="mx-auto"
                            />
                        </div>
                    )}
                    {onboardingStep === "connect" && (
                        <div className="flex flex-col items-center bg-white rounded-lg py-4 px-3 mt-4 mt">
                            <p className="font-bold mb-4">Supported Wallets:</p>
                            <div>
                                <ConnectWallets />
                            </div>
                        </div>
                    )}
                    {onboardingStep === "profile" && (
                        <div className="flex flex-col bg-white rounded-lg py-4 px-3 mt-4 mt">
                            <h3 className="font-bold text-xl text-center mb-3">
                                Make Your DCity Profile
                            </h3>
                            <EditProfileForm
                                saveBtnText="Create It"
                                onSave={() => {
                                    localStorage.setItem("has-profile", "true");
                                    setOnboardingStep("announcements");
                                }}
                            />
                        </div>
                    )}
                    {onboardingStep === "welcome" &&
                        (connectedWallet ? (
                            <div className="w-80 mx-auto mb-8">
                                <Button
                                    $rounded
                                    $primary
                                    $medium
                                    className="w-full"
                                    onClick={() => setOnboardingStep("profile")}
                                >
                                    Continue
                                </Button>
                            </div>
                        ) : (
                            <div className="w-80 mx-auto mb-2">
                                <Button
                                    $rounded
                                    $primary
                                    $medium
                                    className="w-full"
                                    onClick={() => setOnboardingStep("connect")}
                                >
                                    Connect Wallet
                                </Button>
                                <div
                                    role="button"
                                    className="text-primary font-bold text-center text-xs h-7 py-1.5"
                                    onClick={onClickContinueAsGuest}
                                >
                                    Continue as Guest
                                </div>
                            </div>
                        ))}
                    {onboardingStep === "welcome" && (
                        <div
                            className="flex bg-white rounded-lg py-4 px-3"
                            style={{ height: 148 }}
                        >
                            <div className="flex flex-col overflow-auto px-3">
                                <h3 className="font-bold text-center mb-4">
                                    Community Rules
                                </h3>
                                <ol className="list-decimal space-y-2 pl-4 text-sm">
                                    <li>
                                        Treat every community member with
                                        respect. Any form of harassment, witch
                                        hunting, sexism, racism, hate speech or
                                        anything of this nature will NOT be
                                        tolerated.
                                    </li>
                                    <li>
                                        No spam, self-promotion, or shilling of
                                        non affiliated projects. This implies
                                        that server invites, advertisements,
                                        etc., without express permission from a
                                        founder or moderator is prohibited.{" "}
                                    </li>
                                    <li>
                                        No NSFW or abhorrent content. This
                                        includes text, images, videos or links
                                        featuring nudity, sex, hardcore
                                        violence, or other graphically
                                        disturbing content.
                                    </li>
                                    <li>
                                        If you see something against the rules
                                        or something that makes you feel
                                        uncomfortable, please report it to the
                                        DCity team . We want this website to be
                                        a welcoming place for all members of our
                                        community
                                    </li>
                                    <li>
                                        Scams are completely INTOLERABLE. Please
                                        report it immediately if you spot any
                                        scam-like behavior or activity from a
                                        community member, and swift action will
                                        be taken.
                                    </li>
                                    <li>
                                        All chats in the main channels should be
                                        conducted in English. Please contact a
                                        moderator in case you need a new channel
                                        to be created for your language, and we
                                        will see to it that you are
                                        accommodated.
                                    </li>
                                    <li>
                                        Have Fun and Stay Active! The more you
                                        can contribute, the better we can serve
                                        you as community members! We look
                                        forward to bringing you a professional
                                        product that you will be able to utilize
                                        and cherish for years to come.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return null;
};
