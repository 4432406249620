import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";
import { StoreProvider } from "./store";
import { WalletContextProvider } from "./features/wallets/lib";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <StoreProvider>
            <BrowserRouter>
                <WalletContextProvider>
                    <App />
                </WalletContextProvider>
            </BrowserRouter>
        </StoreProvider>
    </React.StrictMode>
);
