import { ButtonHTMLAttributes, FC } from "react";
import tw from "tailwind-styled-components";
import { UserCircleIcon } from "@heroicons/react/24/outline";

import { wallet } from "../assets";

type ButtonStyleProps = {
    $xs?: boolean;
    $small?: boolean;
    $medium?: boolean;
    $rounded?: boolean;
    $dark?: boolean;
    $danger?: boolean;
    $primary?: boolean;
    $secondary?: boolean;
    $outline?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const fgColor = ({
    disabled,
    $outline,
    $dark,
    $primary,
    $secondary,
}: ButtonStyleProps) => {
    if ($outline) {
        if (disabled) {
            return "text-gray-400 border-gray-400";
        } else if ($dark) {
            return "border-gray-900 text-gray-900";
        } else if ($secondary) {
            return "border-neutral-500 text-neutral-500 hover:border-neutral-800 hover:text-neutral-800";
        } else if ($primary) {
            return "border-primary text-primary hover:border-primary-hover hover:text-primary-hover";
        }
    } else if ($dark || $primary || $secondary) {
        return "text-white";
    }

    return "border-transparent text-gray-600";
};

const bgColor = ({
    disabled,
    $outline,
    $dark,
    $danger,
    $primary,
    $secondary,
}: ButtonStyleProps) => {
    if ($outline) {
        if ($dark) {
            return "bg-transparent hover:bg-white active:bg-gray-100";
        } else if ($secondary || $primary) {
            return "bg-white";
        }
    } else if (disabled) {
        return "bg-gray-400";
    } else if ($danger) {
        return "border-red-700 bg-red-700 hover:bg-red-800 hover:border-red-700";
    } else if ($dark) {
        return "border-gray-900 bg-gray-900 hover:bg-gray-800 hover:border-gray-800 active:bg-black active:border-black";
    } else if ($primary) {
        return "border-primary bg-primary hover:bg-primary-hover hover:border-primary-hover active:bg-primary-hover active:border-primary-hover";
    } else if ($secondary) {
        return "border-pink-500 bg-pink-500 hover:bg-pink-600 hover:border-pink-600 active:bg-pink-700 active:border-pink-700";
    }

    return "hover:bg-gray-100";
};

const borderRadius = ({ $rounded }: ButtonStyleProps) => {
    return $rounded ? "rounded-full" : "rounded";
};

const size = ({ $xs, $small, $medium, $rounded }: ButtonStyleProps) => {
    if ($xs) {
        if ($rounded) {
            return "border px-2 h-6 text-xs";
        }
        return "border px-1 h-6 text-xs";
    }

    if ($small) {
        if ($rounded) {
            return "border px-3 h-8 text-sm";
        }
        return "border px-2 h-8 text-sm";
    }

    if ($medium) {
        if ($rounded) {
            return "border px-3 h-10 text-sm";
        }
        return "border px-2 h-10 text-sm";
    }

    return "border-2 px-4 py-2 h-12";
};

export const Button = tw.button<ButtonStyleProps>`
    ${fgColor}
    ${bgColor}
    ${borderRadius}
    ${size}

    font-semibold
` as FC<ButtonStyleProps>;

export const WalletButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
    props
) => {
    const style = {
        ...props.style,
        backgroundImage: `url(${wallet})`,
    };

    return (
        <button
            className="w-10 h-10 flex-none bg-center bg-no-repeat bg-contain block"
            style={style}
        />
    );
};

export const UserButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
    props
) => {
    return (
        <button
            className="w-10 h-10 flex-none bg-center bg-no-repeat bg-contain flex justify-center items-center"
            style={props.style}
        >
            <UserCircleIcon strokeWidth="1.3" width={36} height={36} />
        </button>
    );
};
