import {
    ChangeEvent,
    FC,
    MouseEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { PencilIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import nftNri from "../../nfts/lib/nri.json";
import { icp } from "../../../assets";
import { useNftSearch } from "../lib/use-nft-search";
import { DCityPlotSummary } from "../../../store/api";

import { ToggleButton } from "../../../components/toggle-button";
import { DCityImage } from "./dcity-image";
import { ResultPager } from "./result-pager";

import {
    ClearFilter,
    FilterPill,
    Info,
    PlotData,
    PlotDataMint,
    PlotDataTitle,
    PlotEditButton,
    PlotListItem,
    PlotSaleBanner,
    ScrollContainer,
    SearchField,
    Seperator,
} from "./drawer-plots.styles";
import { currency } from "../../../util/formatters";

const PAGE_SIZE = 60;

const formatPercentage = (percentage: number) => {
    return (percentage * 100).toFixed(1);
};

export type PlotFilters = Map<number, number[]>;

export const DrawerPlots: FC<{
    filters: PlotFilters
    onEnableKeyboard: (enabled: boolean) => void;
    onEdit: (nft: DCityPlotSummary) => void;
    onView: (mint: number) => void;
    onToggleFilterDrawer: () => void;
    onClearFilters: () => void;
}> = ({ filters, onEnableKeyboard, onEdit, onView, onToggleFilterDrawer, onClearFilters }) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState("");
    const [pageIdx, setPageIdx] = useState(0);
    const [onlyMyAssets, setOnlyMyAssets] = useState(false);
    const [onlyForSale, setOnlyForSale] = useState(false);

    const filterCount = useMemo(() => {
        let result = 0;

        filters.forEach((values) => {
            result += values.length;
        });

        return result;
    }, [filters]);

    useEffect(() => {
        setPageIdx(0);

        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0);
        }
    }, [filters]);

    const { isFetching, results, total } = useNftSearch(
        pageIdx,
        PAGE_SIZE,
        filters,
        onlyMyAssets,
        onlyForSale,
        search
    );

    const onClickEdit =
        (nft: DCityPlotSummary) => (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            onEdit(nft);
        };

    return (
        <>
            <SearchField
                placeholder="Search Asset Names or NFT #…"
                value={search}
                onFocus={() => onEnableKeyboard(true)}
                onBlur={() => onEnableKeyboard(false)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                }}
            />
            <ToggleButton
                label="My Assets"
                checked={onlyMyAssets}
                onChange={setOnlyMyAssets}
            />
            <ToggleButton
                label="For Sale"
                checked={onlyForSale}
                onChange={setOnlyForSale}
            />
            <button
                className="flex justify-between items-center rounded-lg py-2 px-3 bg-gray-100 text-cyan-700"
                onClick={onToggleFilterDrawer}
            >
                <div>Filter Attributes</div>
                <div className="flex justify-center items-center">
                    <ChevronRightIcon width={18} height={18} />
                </div>
            </button>
            <div className="flex justify-center items-center py-2 px-3">
                {filterCount ? (
                    <div className="flex space-x-2">
                        <FilterPill>{filterCount} Filter Applied</FilterPill>
                        <ClearFilter
                            role="button"
                            onClick={onClearFilters}
                        >
                            Clear Filters
                        </ClearFilter>
                    </div>
                ) : (
                    <div className="text-sm text-gray-400">
                        No Filters Applied
                    </div>
                )}
            </div>
            <Seperator />
            {isFetching ? (
                <Info>Loading Nft Collection</Info>
            ) : results.length ? (
                <ScrollContainer ref={scrollRef}>
                    {results.map((item) => (
                        <PlotListItem
                            key={item.mint}
                            onClick={() => onView(item.mint)}
                        >
                            <DCityImage mint={item.mint} className="w-14">
                                {!!item.$price && (
                                    <PlotSaleBanner>FOR SALE</PlotSaleBanner>
                                )}
                            </DCityImage>
                            <PlotData>
                                <PlotDataTitle>{item.nickname}</PlotDataTitle>
                                <PlotDataMint>
                                    Plot #{item.mint + 1}
                                </PlotDataMint>
                                <div className="flex space-x-4 text-xs text-gray-700">
                                    {!!item.$price && (
                                        <div className="flex space-x-1 whitespace-nowrap">
                                            <img
                                                src={icp}
                                                alt="ICP"
                                                className="w-4 h-4"
                                            />
                                            <span>{currency(item.$price)}</span>
                                        </div>
                                    )}
                                    <div className="flex space-x-2 whitespace-nowrap">
                                        NRI:{" "}
                                        {formatPercentage(nftNri[item.mint])}%
                                    </div>
                                </div>
                            </PlotData>
                            {item.$isOwned && (
                                <PlotEditButton
                                    type="button"
                                    onClick={onClickEdit(item)}
                                >
                                    <PencilIcon width={16} height={16} />
                                </PlotEditButton>
                            )}
                        </PlotListItem>
                    ))}
                </ScrollContainer>
            ) : (
                <Info>Your search did not match any DCity Plots</Info>
            )}
            <ResultPager
                pageIdx={pageIdx}
                pageSize={PAGE_SIZE}
                totalRecords={total}
                onChangePageIdx={setPageIdx}
            />
        </>
    );
};
