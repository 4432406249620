import Lottie from "react-lottie";

import * as animationData from "../assets/loading_lottie.json";

const LOADING_ANIMATION_OPTIONS = {
    animationData,
    autoplay: true,
    loop: true,
};

export const LoadingAnimation = () => {
    return (
        <Lottie options={LOADING_ANIMATION_OPTIONS} width={320} height={198} />
    );
};
