import { Principal } from "@dfinity/principal";
import { Actor, ActorSubclass, HttpAgent } from "@dfinity/agent";
import { idlFactory, NftService, OkResult, ErrResult } from "./nft.did";

export const DCITY_PRINCIPAL_ID = "gtb2b-tiaaa-aaaah-qcxca-cai";
export const DCITY_PRINCIPAL = Principal.fromText(DCITY_PRINCIPAL_ID);

export const VIBESTER_PRINCIPAL_ID = "2l7rh-eiaaa-aaaah-qcvaa-cai";
export const VIBESTER_PRINCIPAL = Principal.fromText(VIBESTER_PRINCIPAL_ID);

export const isOk = (val: any): val is OkResult => {
    return val && !!val.ok;
};

export const isErr = (val: any): val is ErrResult => {
    return val && !!val.err;
};

export const tokenIdentifier = (principal: Principal, mint: number) => {
    const result = new Uint8Array(
        4 /*prefix*/ + 10 /* canister_id */ + 4 /* index */
    );
    result.set([10, 116, 105, 100], 0); // Buffer("\x0Atid")
    result.set(principal.toUint8Array(), 4); //

    let b = new ArrayBuffer(4);
    new DataView(b).setUint32(0, mint);
    result.set(new Uint8Array(b), 14);

    return Principal.fromUint8Array(result);
};

export const dcityTokenIdentifier = (mint: number) => {
    return tokenIdentifier(DCITY_PRINCIPAL, mint);
};

export const parseTokenMint = (tokenIdentifier: string) => {
    try {
        const principal = Principal.fromText(tokenIdentifier);
        const arr = principal.toUint8Array();

        return new DataView(arr.buffer).getUint32(14);
    } catch {
        return -1;
    }
};

export const tokenThumbnail = (mint: number) => {
    const id = dcityTokenIdentifier(mint).toText();

    return `https://${DCITY_PRINCIPAL_ID}.raw.ic0.app/?cc=0&type=thumbnail&tokenid=${id}`;
};

export const profileThumbnail = (
    [nft_principle]: [Principal] | [],
    [mint]: [number] | []
): string | null => {
    if (nft_principle && typeof mint === "number") {
        const id = tokenIdentifier(nft_principle, mint).toText();

        return `https://${nft_principle.toText()}.raw.ic0.app/?cc=0&type=thumbnail&tokenid=${id}`;
    }

    return null;
};

export const createActor = (canisterId: Principal) => {
    const agent = new HttpAgent({
        host: "https://boundary.ic0.app/",
    });

    return Actor.createActor<NftService>(idlFactory, {
        agent,
        canisterId,
    });
};

export const dcityNft = createActor(DCITY_PRINCIPAL);
export const vibesterNft = createActor(VIBESTER_PRINCIPAL);

export const resolveActor = (
    nftPrincipalId: string
): ActorSubclass<NftService> => {
    switch (nftPrincipalId) {
        case DCITY_PRINCIPAL_ID:
            return dcityNft;
        case VIBESTER_PRINCIPAL_ID:
            return vibesterNft;
        default:
            throw new Error('Nft not found');
    }
};
